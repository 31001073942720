import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Moment from 'react-moment';
import 'moment-timezone';

type props = {
    datos: never[]
}

const TableLog = ({ datos }: props) => {

    return (
        <div>
            <TableContainer component={Paper} sx={{ maxWidth: 650, maxHeight: 600 }}>
                <Table stickyHeader sx={{ maxWidth: 600, maxHeight: 600 }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Fecha</TableCell>
                            <TableCell align="center">Detalle</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datos.map((row) => (
                            <TableRow
                                key={row["fecha"]}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">

                                    <Moment format="DD/MM/YYYY HH:MM:SS">
                                        {row["fecha"]}
                                    </Moment>

                                </TableCell>
                                <TableCell align="right">{row["detalle"]}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
export default TableLog;