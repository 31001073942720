import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Gerencia} from "../../../redux/api/admin/gerencias"
import {pais} from "../../../redux/api/catalogos";


interface Column {
    id: 'codigo' | 'nombre'| 'pais'| 'activo';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'codigo', label: 'Codigo', minWidth: 100 },
    { id: 'nombre', label: 'Nombre', minWidth: 170 },
    { id: 'pais', label: 'Pais',minWidth: 170},
    { id:'activo',label:'Estado', minWidth:50},

];

type props={
    gerencias:Array<Gerencia>,
    paises:Array<pais>
    handlerUpdate:any
}
export default function List({gerencias,paises,handlerUpdate}:props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleRowClick = (element:Gerencia) =>{
        handlerUpdate(element)
    }
    function getPaisName (codigo:any){
        let pais=paises?.find(p=>p.codigo===codigo)
        if(pais!=undefined)
            return pais.nombre
        return codigo
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {gerencias
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo} onClick={()=>handleRowClick(row)}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.id==='activo'?(row.activo?'Activa':'Inactiva'):
                                                     column.id==='pais'?getPaisName(value):
                                                        column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                </TableCell>
                                            );
                                        })}

                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={gerencias.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}