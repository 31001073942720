import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";
import {useEffect} from "react";
import {ThemeProvider} from '@mui/material/styles';
import Themex from "../src/configs/theme";
function App() {
    useEffect(() => {
        document.title = 'AsContables'
    }, []);
  if(!sessionStorage.getItem("jsontoken"))
  try {
      let url_token=window.location.href.split("=",2)[1];
      if(url_token)
          sessionStorage.setItem("jsontoken", url_token);
      else
          window.location.replace(process.env.REACT_APP_B2C_LOGIN!);
  } catch (error) {
      window.location.replace(process.env.REACT_APP_B2C_LOGIN!);
  }
  return (
    <ThemeProvider theme={Themex}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}  >
            {routes}
        </Route>
      </Routes>
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
