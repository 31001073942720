import ASyncSelect from "react-select/async";
import Select from "react-select"
import {FormControl, InputLabel} from "@mui/material";
import {SxProps} from "@mui/system/styleFunctionSx";
import {Theme} from "@mui/system/createTheme";


type props = {
    variable:any,
    fetch:any,
    lista?:any,
    trigger?:any,
    mostrarCodigo?:boolean,
    aSync?:boolean
    onSelect?:any,
    label:string,
    sx?: SxProps<Theme>,
    loadCallback?:any
    error?:boolean,
    isDisable?:boolean
}

const Combo = ({lista,variable,mostrarCodigo=true,fetch,aSync=false,onSelect,label,sx,loadCallback,error=false,isDisable=false}:props) =>{

    const load = (inputValue:any,callback:any)=>{
        if(inputValue.toString().length<1)
            callback(lista)
        let listFilter=lista.filter(function (dato:any){
            return dato.nombre.toLowerCase().includes(inputValue.toLowerCase()) || dato.codigo.toLowerCase().includes(inputValue.toLowerCase());
        })
        callback(listFilter)
    }
    const handlerSelect=(value:any)=>{
        onSelect(value);
        console.log(value);
    }
    if(aSync)
        return <>
            <FormControl style={{minWidth:'90%',maxWidth:'90%',padding:'2rem,3rem,2rem,3rem'}}>
                <ASyncSelect
                    value={variable??[]}
                    cacheOptions
                    loadOptions={loadCallback}
                    defaultOptions
                    isLoading={fetch.isLoading||fetch.isFetching||fetch.isUninitialized}
                    getOptionLabel={(e) => (mostrarCodigo?(e.codigo+' - '):'')+e.nombre}
                    getOptionValue={(e) => e.codigo}
                    noOptionsMessage={()=>'no hay datos'}
                    loadingMessage={()=>'Cargando'}
                    placeholder={'Seleccione'}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? 'transparent' : 'transparent',
                        }),
                    }}
                />
            </FormControl>
        </>
    else
        return <FormControl sx={sx}>
                <InputLabel required size={"normal"} variant={"standard"} shrink={true} error={error}>{label}</InputLabel>
                <Select
                    value={variable??[]}
                    options={lista}
                    isLoading={fetch === undefined ? undefined : fetch.isLoading||fetch.isFetching||fetch.isUninitialized}
                    getOptionLabel={(e) => (mostrarCodigo?(e.codigo+' - '):'')+e.nombre}
                    getOptionValue={(e) => e.codigo}
                    noOptionsMessage={()=>'no hay datos'}
                    loadingMessage={()=>'Cargando'}
                    placeholder={'Seleccione'}
                    onChange={(item)=>handlerSelect(item)}
                    isDisabled={isDisable}


                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderTop:"transparent",
                            borderLeft:"transparent",
                            borderRight:"transparent",
                            borderBottomColor:error?'red':'transparent',
                            boxShadow: 'none',
                            marginTop:'14px',
                            ":hover": {borderBottomColor:error?'red':'#2ed8c3'},
                        }),


                        option:(base,props)=>({
                            ...base,
                            backgroundColor: props.isSelected?"#014751":(props.isFocused?"#2ed8c3":"white"),
                            color:props.isSelected?"white":"black",
                            ':active': {
                                ...base[':active'],
                                backgroundColor: !props.isDisabled
                                    ? props.isSelected
                                        ? "orangered"
                                        : "orange"
                                    : undefined,
                            },
                        }),





                    }}
                />
            </FormControl>

}
export default Combo