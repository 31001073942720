import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      primary: {
        main: '#003D5B',
        light: '#F5EBFF',
      },
      secondary:{
        main: '#cc3262',
        light: '#cc3262'
      }
    },
    typography:{
      fontFamily:"Montserrat"
      ,
      h6:{
        backgroundColor:'#003D5B',
        color:'white'
      },
        h2:{
            backgroundColor:'#cc3262',
            color:'#003D5B'
        }
    },
    components:{
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor:  '#F2F2F2 !important',
            },
            // '&:selected': {
            //   backgroundColor: 'red !important',
            // },
            // '&:active': {
            //   backgroundColor: 'orange !important',
            // },
            
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor:  '#fff !important',
            },
            '&.Mui-selected': {
              backgroundColor: '#d5d5d5 !important',
            },
          },
        },
      }
      ,
        MuiButton:{
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
                            backgroundColor: '#003D5B',
                            textTransform: 'none',
                            borderRadius:'25px',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#2ed8c3',
                                borderColor: '#003D5B',
                                color:'#000',
                                //boxShadow: 'none',
                            },
                            '&:active': {
                                boxShadow: 'none',
                                backgroundColor: 'orange',
                                //borderColor: 'orange',
                            },
                            '&:focus': {
                                boxShadow: '0 0 0 0.1rem #003D5B',
                                backgroundColor: '#2ed8c3',
                                color:'#000',
                            },
                        }),
                }),
        }},



    }


  });

  
export default theme;