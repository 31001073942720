import {Gerencia} from "../../../redux/api/admin/gerencias";
import {Box, Button, Checkbox, TextField, ThemeProvider, Typography, Divider,IconButton} from "@mui/material";
import {Stack,FormControlLabel} from "@mui/material"
import React, {useEffect,useState} from "react";
import Themex from "../../../configs/theme";
import {pais} from "../../../redux/api/catalogos";
import Combo from "../../asientos_contables/components/combo";
import {Area,Etapa} from "../../../redux/api/manageCases";
import {Usuario} from "../../../redux/api/usuarios";
import {useLazyGetAprobadoresQuery,Aprobador,useSaveGerenciaMutation} from "../../../redux/api/admin/gerencias";
import {AreaBox} from "./components/area";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: '0px 2px 24px #383838',
    //height: "80%",
    padding: '16px 32px 24px 32px',
    textAlign: "left",
    overflowY: "scroll",
    maxHeight: "90%",

};

type props= {
    nuevo: boolean,
    actualizar?: Gerencia,
    gerencias: Array<Gerencia>,
    fetchPaises: any,
    fetchAreas: any,
    fetchEtapas: any,
    fetchUsuarios: any
    admin: string,
    onClose: any,

}

const Editar = React.forwardRef(({nuevo,actualizar,gerencias,fetchPaises,fetchAreas,admin,onClose,fetchUsuarios,fetchEtapas}:props,ref) => {
    const [codigo,setCodigo]  = useState<string>()
    const [nombre,setNombre]  = useState<string>()
    const [pais,setPais]      = useState<pais>()
    const [activo,setActivo]  = useState<boolean>(true)
    const [areas,setAreas]    =   useState<Array<Area>>([])
    const [area,setArea] = useState<Area>()//variable temporal
    useEffect(()=>{
        if(!nuevo){
            setCodigo(actualizar?.codigo)
            setNombre(actualizar?.nombre)
            let _pais=fetchPaises.data?.Paises?.find((p:pais) => p.codigo==actualizar?.pais)
            if(_pais!==undefined)
                setPais(_pais)
            setActivo(actualizar?.activo??true)
            let _areas=actualizar?.areas?.split(',')??''
            let _newAreas:Array<Area>=[]
            fetchAreas.data?.Areas.forEach((a:Area)=>{
                if(_areas?.indexOf(a.codigo)>=0){
                    _newAreas=[..._newAreas,a]
                }
            })
            setAreas(_newAreas)

            triggerAprobadores(actualizar?.codigo??'')
        }
    },[nuevo,actualizar])

    const [aprobadores,setAprobadores]=useState<Array<Aprobador>>([])
    let [triggerAprobadores,fetchAprobadores] = useLazyGetAprobadoresQuery()
    let [triggerSaveGerencia] = useSaveGerenciaMutation()
    useEffect(()=>{
        if(!fetchAprobadores.isFetching&&fetchAprobadores.isSuccess&&!fetchAprobadores.isLoading)
            setAprobadores(fetchAprobadores.data?.Aprobadores)
    },[fetchAprobadores])

    const [user,setUser] = useState<Usuario>()

    let codGerencias=gerencias.map(g=>g.codigo)
    let validCode = (code:string) => {
        if(!nuevo)
            return true
        if(code.length>4)
            return false
        if(codGerencias.indexOf(code.toUpperCase())>=0)
            return false;
        return true;
    }
    let AreasFaltantes = () =>{
        let _areas=areas.map(a=>a.codigo)
        return fetchAreas.data?.Areas.filter((a:Area)=>{
            if(_areas?.indexOf(a.codigo)>=0)
                return false;
            return true;
        })
    }

    const handlerAgregarArea = () =>{
        let _areas=areas
        if(area!=undefined)
            _areas.push(area)
        setAreas(_areas)
        setArea(undefined)
    }
    const handlerAgregarAprobador = (_area:string,_etapa:number,_aprobador:string)=>{
        if(_aprobador==='')
            return
        let _gerencia_etapa=aprobadores.find(aprob=>aprob.area==_area&&aprob.etapa==_etapa)
        let _aprobadores=_gerencia_etapa?.aprobadores?.split(',')
        if(_aprobadores!=undefined) {
            _aprobadores.push(_aprobador)
            let newApro=aprobadores.map(value=>{
                if(value.area==_area&&value.etapa==_etapa)
                    return {...value,aprobadores:_aprobadores?.join(',')}
                return value
            })
            setAprobadores(newApro)
        }else{
            _aprobadores=[_aprobador]
            let _newAprobador:Aprobador={}
            _newAprobador.aprobadores=_aprobadores.join(',')
            _newAprobador.area=_area
            _newAprobador.etapa=_etapa
            setAprobadores([...aprobadores,_newAprobador])
        }


        if(_gerencia_etapa==undefined)
            return

    }
    const handlerEliminarAprobador = (_area:string,_etapa:number,_aprobador:string)=>{
        let _gerencia_etapa=aprobadores.find(aprob=>aprob.area==_area&&aprob.etapa==_etapa)
        let _aprobadores=_gerencia_etapa?.aprobadores?.split(',').filter(value=>value!==_aprobador&&value!='')
        let newApro=aprobadores.map(value=>{
            if(value.area==_area&&value.etapa==_etapa)
                return {...value,aprobadores:_aprobadores?.join(',')}
            return value
        })
        setAprobadores(newApro)
    }
    const handlerClicGuardar = () =>{
        let _gerencia:Gerencia={}
        _gerencia.codigo=codigo
        _gerencia.nombre=nombre
        _gerencia.pais=pais?.codigo
        _gerencia.activo=activo
        _gerencia.areas=areas.map(a=>a.codigo).join(',')

        let formData=new FormData()
        formData.append("gerencia",JSON.stringify(_gerencia))
        formData.append("aprobadores",JSON.stringify(aprobadores))
        triggerSaveGerencia(formData)
            .unwrap()
            .then((response)=> {
                if(response.ok)
                    onClose()

            }
            )
    }
    return (
            <Box sx={style} ref={ref}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: 'center'}}>
                    {nuevo===true?"Crear Nueva Gerencia":"Modificar Gerencia"}
                </Typography>
                <Divider orientation="horizontal" flexItem />
                <ThemeProvider theme={Themex}>
                    <Stack spacing={{ xs: 1, sm: 2}} direction="column" useFlexGap>
                        <Box component="form" autoComplete="off" sx={{
                            '& .MuiTextField-root': {m: 2, width: '25ch'},
                            backgroundColor: 'white',
                            m: 1, borderRadius: '10px'
                        }}>
                            <TextField label="Codigo" variant="standard" value={codigo} error={!(validCode(codigo??''))} onChange={(evt)=>setCodigo(evt.target.value)} InputProps={{readOnly: !nuevo,}} sx={{minWidth:1/5, with:'10ch',maxWith:'10ch'}}/>
                            <TextField label="Nombre" variant="standard" value={nombre} onChange={(evt)=>setNombre(evt.target.value)} InputProps={{readOnly: false,}} sx={{minWidth:1/5, maxWith:'30ch'}}/>
                            <Combo variable={pais} fetch={fetchPaises} lista={fetchPaises.data?.Paises} label={'Pais'}
                                   onSelect={(p:pais)=>setPais(p)} mostrarCodigo={false} isDisable={!nuevo}
                                   sx={{minWidth: 1/5, m: 2, width: '25ch'}}/>
                            <FormControlLabel control={<Checkbox checked={activo} onClick={()=>setActivo(!activo)}/>} label="Activo" sx={{minWidth:1/5,borderRadius:'10px',m:3}}/>
                        </Box>
                        <Divider orientation="horizontal" flexItem />
                        { AreasFaltantes()?.length>0 &&
                            <Box component="form" autoComplete="off" sx={{
                                '& .MuiTextField-root': {m: 1, width: '25ch'},
                                backgroundColor: 'white'
                            }}>
                                <Combo variable={area} fetch={fetchUsuarios} label={'Areas'} lista={AreasFaltantes()} onSelect={(a:Area)=>setArea(a)}
                                       sx={{minWidth: 1/5, m: 1/2, width: '25ch'}} mostrarCodigo={false}/>
                                <Button variant="contained" sx={{m:2}} onClick={handlerAgregarArea}>Agregar Area</Button>
                            </Box>
                        }
                        {areas.map((area,index)=>
                        {
                            return (
                                <AreaBox key={"ac_ger_edit_area_"+index} area={area} fetchEtapas={fetchEtapas} aprobadores={aprobadores}
                                         addAprobador={handlerAgregarAprobador} fetchUsers={fetchUsuarios} removeAprobador={handlerEliminarAprobador}/>
                            )
                        })}
                    </Stack>
                    <Box>
                        <Button variant="contained" onClick={handlerClicGuardar}>Guardar</Button>
                    </Box>

                </ThemeProvider>
            </Box>

    )
})
export default Editar