import React, {useState, useEffect, ChangeEvent, useCallback} from "react";
import {
    Box,
    FormControl,
    InputLabel,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {TextField,Button} from "@mui/material";
import {ThemeProvider} from '@mui/material/styles';
import Themex from '../../configs/theme'
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider} from '@mui/x-date-pickers';
import "dayjs/locale/es";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AddIcon from '@mui/icons-material/Add';

import Combo from "./components/combo"
import {pais, empresa, articulo, catalogosApi, tipoCompra, catalogoGenerico} from "../../redux/api/catalogos";
import TablaDetalles, {Detalle} from "./components/tablaDetalles";
import {useFindUserQuery,Usuario} from "../../redux/api/usuarios";
import userToken from "../../configs/userTokenDecrypt";

import {manageCasesApi,anexo} from "../../redux/api/manageCases";
import {Gerencia, useGetAllGerenciasQuery} from "../../redux/api/admin/gerencias"
import Table from "@mui/material/Table";
import { useDropzone } from "react-dropzone";
import { Container,DropZoneContainer,FileListContainer,FileList,StyledFile } from "../../configs/inputFileStyles";
import {AsientoContable,useSaveAsientoContableMutation} from "../../redux/api/admin/asiento_contable"
import {useSnackbar, closeSnackbar,VariantType} from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate} from "react-router-dom";

const AsientoContableNuevo = () => {
    const navigate = useNavigate();
    const [solicitante, setSolicitante] = useState<Usuario>()
    const [fecha,] = useState(new Date(Date.now()).toLocaleDateString())
    const [pais, setPais] = useState<pais>()
    const [empresa, setEmpresa] = useState<empresa>()
    const [departamento, setDepartamento] = useState<{codigo:string}>()
    const [prioridad, setPrioridad] = useState<{codigo:string}>()
    const [descripcion, setDescripcion] = useState()
    const [comentario, setComentario] = useState('')

    const [anexos, setAnexos] = useState<anexo[]>([])

    const [articulo, setArticulo] = useState<articulo>()
    const [descripcion2, setDescripcion2] = useState('')
    const [cantidad, setCantidad] = useState('')
    const [monto, setMonto] = useState('')
    const [tipoCompra, setTipoCompra] = useState<tipoCompra>()
    const [fechaRequerida, setFechaRequerida] = useState<Dayjs | null>(dayjs(new Date(Date.now()).toDateString()))
    const [comprador, setComprador] = useState()
    const [ceco, setCeCo] = useState<catalogoGenerico>()
    const [cliente, setCliente] = useState()
    const [servicio, setServicio] = useState()
    const [site, setSite] = useState()
    const [car, setCar] = useState()
    const [comentarioLinea, setComentarioLinea] = useState('')
    const [detalles, setDetalles] = useState<Detalle[]>([])
    const [gerencia, setGerencia] = useState<Gerencia>()

    const fetchPaises = catalogosApi.endpoints.fetchPaises.useQuery("false")
    const fetchDepartamentos = catalogosApi.endpoints.fetchDepartamentos.useQuery()
    const fetchPrioridades = catalogosApi.endpoints.fetchPrioridades.useQuery()
    const [triggerEmpresas, fetchEmpresas] = catalogosApi.endpoints.fetchEmpresas.useLazyQuery()

    const [triggerArticulos, fetchArticulos] = catalogosApi.endpoints.fetchArticulos.useLazyQuery()
    const [triggerCeCos, fetchCeCos] = catalogosApi.endpoints.fetchCeCos.useLazyQuery()
    const [triggerTiposCompra, fetchTiposCompra] = catalogosApi.endpoints.fetchTiposCompra.useLazyQuery()
    const [triggerComprador, fetchComprador] = catalogosApi.endpoints.fetchCompradores.useLazyQuery()
    const [triggerClientes, fetchClientes] = catalogosApi.endpoints.fetchClientes.useLazyQuery()
    const [triggerServicios, fetchServicios] = catalogosApi.endpoints.fetchServicios.useLazyQuery()
    const [triggerSites, fetchSites] = catalogosApi.endpoints.fetchSites.useLazyQuery()
    const [triggerCars, fetchCars] = catalogosApi.endpoints.fetchCars.useLazyQuery()

    let _filtroGerencias = new FormData()
    _filtroGerencias.append("soloActivos", 'true')
    const fetchGerencias = useGetAllGerenciasQuery(_filtroGerencias)

    const [triggerAnexosUpload] = manageCasesApi.endpoints.anexosUpload.useMutation()
    const [triggerAnexosDownload] = manageCasesApi.endpoints.anexosDownload.useMutation()

    const [triggerSaveAsientoContable]=useSaveAsientoContableMutation()
    const [file, setFile] = useState<File[]>([]);
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files);
            setFile(filesArray);
        }
    };
    const setAnexoUpload = async (reg:anexo)=>{
        anexos.push(reg)
        setFile([])
    }
    var listaArticulos = [
        {
            "empresa": null,
            "codigo": "JE CODE",
            "nombre": "JE CODE",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "AP",
            "nombre": "AP",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "INTERCO",
            "nombre": "INTERCO",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "LEG",
            "nombre": "LEG",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "TRE",
            "nombre": "TRE",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "AR",
            "nombre": "AR",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "ASSETS",
            "nombre": "ASSETS",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "REC",
            "nombre": "REC",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "NOM",
            "nombre": "NOM",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "TAXES",
            "nombre": "TAXES",
            "unidadMedida": "",
            "grupo": ""
        },
    ];
    const subirArchivo = () => {
        if (!file.length) {
            return;
        }

        file.forEach((fileItem) => {
            let formData = new FormData();
            formData.append("file", fileItem);
            triggerAnexosUpload(formData)
                .unwrap()
                .then((adjunto)=>{
                    setAnexoUpload(adjunto)
                })
                .catch(err=>console.log(err))


        });

    };
    const descargarArchivo = async(index: number) =>{

        if(anexos?.length<=index){
            return}

        const formData = new FormData()
        formData.append("archivo",JSON.stringify(anexos[index]))
        await triggerAnexosDownload(formData)
            .unwrap()
            .then((response)=>{
                const fileurl = window.URL.createObjectURL(response as Blob);
                const link = document.createElement("a");
                link.href = fileurl
                link.setAttribute('download', anexos[index].nombre);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error)=>console.log(error))

    }
    const eliminarArchivo = (index:number)=>{
        let nuevosAnexos=[...anexos]
        nuevosAnexos.splice(index,1)
        setAnexos(nuevosAnexos)
    }
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setFile ((prevFiles) => [...prevFiles, ...acceptedFiles])
              },
        [file]
    );

        const {getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})
        const removeFile = (index: number) => {
            const newFiles = [...file];
            newFiles.splice(index,1)
            setFile(newFiles)

        }






    let _userToken = new userToken();
    let _filtroUsuario=new FormData()
    _filtroUsuario.append('filter',"usuario")
    _filtroUsuario.append('param',_userToken.getEmail())
    let fetchUser=useFindUserQuery(_filtroUsuario)

    useEffect(() => {
        document.title = 'Asientos Contables - Nuevo'
        if(fetchUser.isSuccess)
            setSolicitante(fetchUser.data.Usuario)
    }, []);
    useEffect(() => {
        setEmpresa(undefined)
        if (pais !== undefined) {
            let _form = new FormData();
            _form.append("pais", pais?.codigo ?? '')
            triggerEmpresas(_form)

        }
    }, [pais,triggerEmpresas])
    useEffect(() => {
        setArticulo(undefined)
        if (empresa !== undefined) {
            let _form = new FormData()
            _form.append("empresa", empresa?.codigo ?? '')
            triggerArticulos(_form);
            triggerCeCos(_form)
            triggerTiposCompra()//no recibe nada porque vienen de fw no de sap
            triggerComprador(_form)
            triggerClientes(_form)
            triggerServicios(_form)
            triggerSites(_form)
            triggerCars(_form)
            console.log(fetchArticulos.data?.Articulos);
        }
    }, [empresa,triggerArticulos,triggerCeCos,triggerTiposCompra,triggerComprador,triggerClientes,triggerSites,triggerCars,triggerServicios])

    const handlerSelectPais = (p: pais) => {
        setPais(p)
        setError({...error,pais:false})
    }
    const handlerSelectEmpresas = (emp: empresa) => {
        setEmpresa(emp)
        setError({...error,empresa:false,pais:false})
    }
    const handlerSelectDepartamento = (dep: any) => {
        setDepartamento(dep)
        setError({...error,departamento:false})
    }
    const handlerSelectPrioridad = (p: any) => {
        setPrioridad(p)
        setError({...error,prioridad:false})
    }
    const handlerChangeDescripcion =({target:{value}}:any)=>{
        setDescripcion(value)
        setError({...error,descripcion:false})
    }


    const handlerSelectArticulo = (art: articulo) => {
        setArticulo(art)
        setCeCo(undefined)
        setTipoCompra(undefined)
        setCeCo(undefined)
        setCliente(undefined)
        console.log(art);
    }

    // const handlerSelectComprador = (c:any) => {setComprador(c)}
    const handlerChangeDescripcion2 = ({target:{value}}:any)=>{setDescripcion2(value)}
    const handlerChangeCantidad = ({target:{value}}:any)=>{setCantidad(value)}
    const handlerChangeMonto = ({target:{value}}:any)=>{
        setMonto(value.replace(/[a-z]/gi, ''))
    }


    const filtrarGerencia = () =>{
        if(fetchGerencias.isUninitialized||fetchGerencias.isFetching||fetchGerencias.isLoading)
            return undefined

        if(pais!==undefined)
            return fetchGerencias.data?.Gerencias.filter(g=>g.pais===pais?.codigo&&g.activo===true)

        return fetchGerencias.data?.Gerencias
    }

    const [detError,setDetError]=useState<any>([]);
    const handlerClickAgregar=()=>{
        const fechaActual = new Date();
        fechaActual.setHours(0, 0, 0, 0);
        let deta:Detalle={}
        deta.articulo=articulo
        deta.descripcion2=descripcion2
        deta.cantidad=Number(cantidad)
        deta.tipoCompra=undefined
        deta.fechaRequerida=undefined
        deta.comprador=undefined
        deta.centroCosto=undefined
        deta.cliente=undefined
        deta.servicio=undefined
        deta.site=undefined
        deta.car=undefined
        deta.comentario=undefined
        deta.gerencia=gerencia
        let detaError:any = [];
        if(deta.articulo===undefined)   detaError.articulo=true
        if(deta.descripcion2==="")      detaError.descripcion2=true
        if(deta.cantidad<=0)            detaError.cantidad=true
        // if(deta.tipoCompra===undefined) detaError.tipoCompra=true
        // if((new Date(String(deta.fechaRequerida))).getTime()<(fechaActual.getTime()))
        //     detaError.fechaRequerida=true
        // if(deta.comprador===undefined)  detaError.comprador=true
        // if(deta.centroCosto===undefined)detaError.centroCosto=true
        // if(deta.cliente===undefined)    detaError.cliente=true
        // if(deta.servicio===undefined)   detaError.servicio=true
        // if(deta.site===undefined)       detaError.site=true
        // if(deta.car===undefined)        detaError.car=true
        if(deta.gerencia===undefined)        detaError.gerencia=true
        //if(deta.comentario==="")        detaError.comentario=true //no se valida en fw

        console.log(deta)

        if(Object.keys(detaError).length===0)
            setDetalles([...detalles,deta])
        setDetError(detaError)
    }
    const handlerClickEliminarSeleccionLista=(seleccionados:Detalle[])=>{
        //si se carga desde base de datos la lista se deberia pedir confirmacion para eliminar
        let det2=detalles
        seleccionados.forEach(item=>{
            det2=det2.filter(deta=>deta!==item)
        })
        setDetalles(det2)

    }
    const [error,setError]=useState<any>([]);
    const { enqueueSnackbar } = useSnackbar();
    const mostrarMensajeInformativo = (mensaje:string,variante:VariantType='warning')=>{
        enqueueSnackbar(mensaje,{
            anchorOrigin: { vertical: 'top', horizontal: 'right'},
            variant: variante,
            action: (key) => (
                <IconButton onClick={() => { closeSnackbar(key) }}>
                    <CloseIcon />
                </IconButton>
            ) });
    }
    const handlerClickGuardar=()=>{
        let _ac:AsientoContable={}
        _ac.descripcion=descripcion
        _ac.solicitante=solicitante?.usuario
        _ac.pais=pais?.codigo
        _ac.empresa=empresa?.codigo
        _ac.area=solicitante?.area
        _ac.fecha=fecha
        _ac.prioridad=prioridad?.codigo
        _ac.departamento=departamento?.codigo
        _ac.comentario=comentario
        _ac.monto=monto

        let errores:any = [];
        if((_ac.descripcion??"").trim()==="") errores.descripcion=true
        if(_ac.departamento===undefined) errores.departamento=true
        if(_ac.empresa===undefined) errores.empresa=true
        if(_ac.pais===undefined) errores.pais=true
        if(_ac.prioridad===undefined) errores.prioridad=true
        if((_ac.monto??"").trim()==="") errores.monto=true


        if(Object.keys(errores).length===0)
            if(detalles.length>0)
                if(anexos.length>0)
                {
                    let formData=new FormData()
                    formData.append("AsientoContable",JSON.stringify(_ac))
                    formData.append("Detalles",JSON.stringify(detalles))
                    formData.append("Anexos",JSON.stringify(anexos))
                    triggerSaveAsientoContable(formData)
                         .unwrap()
                         .then((response)=> {
                             if(response){
                                 mostrarMensajeInformativo('Caso Guardado Correctamente','success')
                                 navigate('/')
                             }
                         }
                         ).catch(err=>{
                            mostrarMensajeInformativo('Error al intentar guardar','error')
                            console.log(err)
                        })


                }
                else
                    mostrarMensajeInformativo('Faltan anexos')
            else
                mostrarMensajeInformativo('No se a agregado ningún ´Tipo Journal´')
        else
            mostrarMensajeInformativo('Faltan datos generales')

        setError(errores)

    }

    return (
        <div style={{  width: "100%" }}>
            <Box component="form"
                 autoComplete="off"
                 sx={{
                     '& .MuiTextField-root': {m: 2, width: '25ch'},
                     backgroundColor: 'white',
                     m: 1, borderRadius: '10px'
                 }}
            >
                <ThemeProvider theme={Themex}>
                    <Typography variant="h6" sx={{my: 1, borderRadius: '4px',m:1}}>
                        Datos Generales
                    </Typography>
                    <TextField label="Fecha" variant="standard" value={fecha} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                    <TextField label="Area" variant="standard" value={solicitante?.area} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                    <Combo variable={departamento} fetch={fetchDepartamentos}
                           lista={fetchDepartamentos.data?.Departamentos} label={'Departamentos'}
                           onSelect={handlerSelectDepartamento} mostrarCodigo={false} error={error.departamento}
                           sx={{minWidth: 1/4, m: 2,width: '25ch'}}/>

                    <TextField label="Solicitante" variant="standard" value={solicitante?.nombre} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                    <Combo variable={pais} fetch={fetchPaises} lista={fetchPaises.data?.Paises} label={'Pais'}
                           onSelect={handlerSelectPais} mostrarCodigo={false} error={error.pais}
                           sx={{minWidth: 1/5, m: 2, width: '25ch'}}/>
                    <Combo variable={empresa} fetch={fetchEmpresas} lista={fetchEmpresas.data?.Empresas}
                           label={'Empresa'} onSelect={handlerSelectEmpresas} mostrarCodigo={false} error={error.empresa}
                           sx={{minWidth: 1/4, m: 2, width: '25ch'}}/>
                    <Combo variable={prioridad} fetch={fetchPrioridades} lista={fetchPrioridades.data?.Prioridades}
                           label={'Prioridad'} onSelect={handlerSelectPrioridad} mostrarCodigo={false} error={error.prioridad}
                           sx={{minWidth: 1/10, m: 2, width: '15ch'}}/>
                    <TextField label='Descripción General'
                               variant='standard' value={descripcion}
                               onChange={handlerChangeDescripcion}
                               error={error.descripcion}
                               sx={{minWidth: 2/6,width: 'fill'}}/>
                    <TextField label={'Monto: ' + (pais?.simbolo !== undefined ? pais?.simbolo : '')}
                               variant='standard' value={monto}
                               onChange={handlerChangeMonto}
                               error={error.monto}
                               sx={{minWidth:1/5}}/>

                </ThemeProvider>
            </Box>
            <Box component="form"
                 autoComplete="off"
                 sx={{
                     '& .MuiTextField-root': {m: 2, width: '25ch'},
                     backgroundColor: 'white',
                     m: 1, borderRadius: '10px'
                 }}
            >
                <ThemeProvider theme={Themex}>
                    <Typography variant="h6" sx={{my: 1, borderRadius: '4px',m:1}}>
                        Adjuntos
                    </Typography>
                    <Container>
                    <DropZoneContainer {...getRootProps()}>
                        <input onChange={handleFileChange} {...getInputProps()}/>
                        {isDragActive ? (
                            <p>Suelte los archivos aquí...</p>
                        ) : (
                            <p >Pulse aqui para agregar archivos</p>
                        )}
                    </DropZoneContainer>

                    {file.length > 0 && (
                        <FileListContainer>
                        <FileList>
                            {file.map((file, index) =>(
                             <StyledFile key={"asiento_contable_nuevo_anexo-"+index}>
                                {file.name}
                                <Button variant="contained" color='secondary' sx={{m:1}} onClick={() => removeFile(index)}>
                                    Eliminar
                                </Button>

                             </StyledFile>
                            ))}
                        </FileList>
                      <Button variant="contained" sx={{m:2}} onClick={subirArchivo} >Subir Archivos</Button>
                      </FileListContainer>
                    )}
            </Container>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableBody>
                                {anexos.map((row,index) => (
                                    <TableRow
                                        key={"asiento_contable_nuevo_anexo_"+row.guid}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" sx={{width:'80%'}}>
                                            {row.nombre}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Button  variant="contained" component="label" onClick={()=>descargarArchivo(index)}>Descargar</Button>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button  variant="contained"  color='secondary' component="label" onClick={()=>eliminarArchivo(index)}>Eliminar</Button>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </ThemeProvider>
            </Box>

            <Box component="form"
                 autoComplete="off"
                 sx={{
                     '& .MuiTextField-root': {m: 2, width: '25ch'},
                     backgroundColor: 'white',
                     m: 1, borderRadius: '12px'
                 }}>
                <ThemeProvider theme={Themex}>
                    <Typography variant="h6" sx={{my: 1, borderRadius: '4px',m:1}}>
                        Detalle Asiento Contable
                    </Typography>
                    {/* <Combo label={'Articulo'} fetch={fetchArticulos} variable={articulo} error={detError.articulo}
                           lista={fetchArticulos.data?.Articulos} onSelect={handlerSelectArticulo}
                           sx={{minWidth: "40%", m: 2}}/> */}

<                   Combo label={'Tipo Journal'} fetch={undefined} variable={articulo} error={detError.articulo}
                           lista={listaArticulos} onSelect={handlerSelectArticulo} mostrarCodigo={false} 
                           sx={{minWidth: "40%", m: 2}}/>

                    <TextField  variant='standard' sx={{minWidth: "40%",m:2}} error={detError.descripcion2}
                                label='Descripción 2' value={descripcion2} onChange={handlerChangeDescripcion2}/>
                    <TextField type="number" InputProps={{ inputProps: { min: 0} }} error={detError.cantidad}
                               label='Cantidad' value={cantidad} onChange={handlerChangeCantidad}
                               variant='standard' sx={{minWidth: "5%",maxWidth:"5%"}}/>


                    {/* <Combo label={'Tipo de Compra'}  fetch={fetchTiposCompra} variable={tipoCompra} error={detError.tipoCompra}
                           lista={filtrarTipoCompra()} onSelect={handlerSelectTipoCompra}
                           sx={{minWidth: '20%', m: 1, width: '25ch'}}/>
                    <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
                        <FormControl sx={{m:1}}>
                            <InputLabel required size={"normal"} variant={"standard"} shrink={true} error={detError.fechaRequerida}>Fecha Requerida</InputLabel>
                            <DemoContainer components={['DatePicker']} sx={{padding:"0 0 0 0"}}>
                                <DatePicker
                                    label=""
                                    value={fechaRequerida}
                                    onChange={handlerFechaRequerida}
                                    format={'DD/MM/YYYY'}
                                    sx={{fieldset:{
                                        borderTop:"transparent",
                                            borderLeft:"transparent",
                                            borderRight:"transparent",
                                            borderBottom:detError.fechaRequerida?"red":"primary.main"
                                    },
                                    input:{
                                        boxSizing: "inherit"
                                    }}}

                                />
                            </DemoContainer>
                        </FormControl>
                    </LocalizationProvider> */}
                    {/* <Combo label={'Solicitante'} fetch={fetchComprador} variable={comprador} error={detError.comprador}
                           lista={fetchComprador.data?.Compradores} onSelect={handlerSelectComprador}
                           sx={{minWidth: '35%', m: 1, width: '25ch'}} mostrarCodigo={false}/> */}
                    {/* <Combo label={'Centro de Costo'} fetch={fetchCeCos} variable={ceco}  error={detError.centroCosto}
                           lista={filtrarCeCos()} onSelect={handlerSelectCeCo}
                           sx={{minWidth: '20%', m: 1, width: '25ch'}}/> */}
                    {/* <Combo label={'Cliente'} fetch={fetchClientes} variable={cliente} error={detError.cliente}
                           lista={filtrarCliente()} onSelect={handlerSelectCliente}
                           sx={{minWidth: '25%', m: 1, width: '25ch'}}/> */}
                    {/* <Combo label={'Servicio'} fetch={fetchServicios} variable={servicio} error={detError.servicio}
                           lista={fetchServicios.data?.Servicios} onSelect={handlerSelectServicio}
                           sx={{minWidth: '25%', m: 1, width: '25ch'}}/>
                    <Combo label={'Site'} fetch={fetchSites} variable={site} error={detError.site}
                           lista={fetchSites.data?.Sites} onSelect={handlerSelectSite}
                           sx={{minWidth: '20%', m: 1, width: '25ch'}}/>
                    <Combo label={'CAR'} fetch={fetchCars} variable={car} error={detError.car}
                           lista={fetchCars.data?.CARs} onSelect={handlerSelectCar}
                           sx={{minWidth: '25%', m: 1, width: '25ch'}}/> */}
                    <Combo label={'Gerencia'} fetch={fetchGerencias} variable={gerencia} error={detError.gerencia}
                           lista={filtrarGerencia()} onSelect={(g:Gerencia)=>setGerencia(g)}
                           sx={{minWidth: '25%', m: 1, width: '25ch'}}/>
                    {/* <TextField  variant='standard' sx={{minWidth: 1/3}} error={detError.comentario}
                                label='Comentario de linea' value={comentarioLinea} onChange={handlerChangeComentarioLinea}/>
                    <TextField  variant='standard' sx={{minWidth: 1/6}} InputProps={{readOnly: true,} }
                                label='Unidad de medida' value={articulo?.unidadMedida??' '}
                    /> */}
                    <Button variant="contained" startIcon={<AddIcon />} sx={{m:2}} onClick={handlerClickAgregar}>Agregar</Button>
                </ThemeProvider>
            </Box>
            <TablaDetalles detalles={detalles} eliminarSeleccionados={handlerClickEliminarSeleccionLista}/>
            <Box component="form"
                 autoComplete="off"
                 sx={{
                     '& .MuiTextField-root': {m: 2, width: '25ch'},
                     backgroundColor: 'white',
                     m: 1, borderRadius: '12px'
                 }}>
                <ThemeProvider theme={Themex}>
                    <TextField label="Comentarios Generales" variant="standard" value={comentario}
                               InputProps={{readOnly: false,}} sx={{minWidth:8/9}}
                               onChange={(event:any)=>setComentario(event.target.value)}
                    />
                    <Button variant="contained" sx={{m:2}} onClick={handlerClickGuardar}>Guardar</Button>
                </ThemeProvider>
            </Box>

        </div>


    );

}
export default AsientoContableNuevo