import {ListItemButton, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../../configs/colorConfigs";
import { RootState } from "../../redux/store";
import { RouteType } from "../../routes/config";

type Props = {
  item: RouteType;
  open:boolean
};

const SidebarItemGeneric = ({ item,open }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState);

  return (
    item.sidebarProps && item.path ? (
        <Tooltip title={item.sidebarProps.displayText} disableHoverListener={open} placement="right">
      <ListItemButton
        dense={false}
        component={Link}
        to={item.path}
        sx={{
          "&: hover": {
            backgroundColor: colorConfigs.sidebar.hoverBg
          },
          backgroundColor: appState === item.state ? colorConfigs.sidebar.activeBg : "unset",

          position: "absolute",
            minHeight: 48,
          width: "100%",
            px: 2.5,
            bottom:0
        }}
      >

          <ListItemIcon style={{ position: "relative", justifyContent: "center"}}
                        sx={{color: colorConfigs.sidebar.color,
                            minWidth: 0,
                            mr: open ? 2 : 'auto',
                            justifyContent: 'center',}}>
            {item.sidebarProps.icon && item.sidebarProps.icon}
          </ListItemIcon>
            <ListItemText primary={item.sidebarProps.displayText} sx={{ opacity: open ? 1 : 0 }}/>



      </ListItemButton>
        </Tooltip>
    ) : null
  );
};

export default SidebarItemGeneric;