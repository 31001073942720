import { configureStore } from "@reduxjs/toolkit";
import appStateSlice from "./features/appStateSlice";
import {catalogosApi} from "./api/catalogos";
import {usuariosApi} from "./api/usuarios";
import {manageCasesApi} from "./api/manageCases";

export const store = configureStore({
  reducer: {
    appState: appStateSlice,  //en teoria no se utiliza para nada, verificar antes de eliminar este reducer
    [catalogosApi.reducerPath]:catalogosApi.reducer,
    [usuariosApi.reducerPath]:usuariosApi.reducer,
    [manageCasesApi.reducerPath]:manageCasesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({serializableCheck:false}).concat(
          catalogosApi.middleware,
          usuariosApi.middleware,
          manageCasesApi.middleware),

});

export type RootState = ReturnType<typeof store.getState>;