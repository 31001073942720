import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export type Usuario={
    codigo?:number,
    usuario:string,
    nombre:string,
    roles?:string,
    area:string,
    activo:boolean,
    contra?:string,
    permisos?:Array<string>
};
let suscripcion_key = process.env.REACT_APP_SUSCRIPCION_KEY;
export type usuarios={Usuarios:Array<Usuario>};
console.log(suscripcion_key);
export const usuariosApi = createApi(
    {
        reducerPath:'UsuariosApi',
        baseQuery: fetchBaseQuery({baseUrl:process.env.REACT_APP_API_ASIENTOS_CONTABLES_USUARIOS}),
        tagTypes:["usuariosUpdate"],
        endpoints:(builder)=>({
            findUser:builder.query<{ Usuario:Usuario },FormData>({
                query:(_body)=>({
                    url:'Find',
                    method:'POST',
                    body:_body, 
                    headers: {
                        'Subscription-Key': suscripcion_key, 
                      },
                }),
                transformResponse: (response) => {
                    let data:any = response;
                    if (data?.Usuario?.roles) {
                        data.Usuario.permisos = data.Usuario.roles.split(',');
                    }
                    return data;
                },
                providesTags:["usuariosUpdate"],
            }),
            getAllUsers:builder.query<usuarios,FormData>({
                query:(_body)=>({
                    url:'GetAll',
                    method:'POST',
                    body:_body,  //se puede especificar si traer usuarios inactivos,
                    headers: {
                        'Subscription-Key': suscripcion_key, 
                      },
                }),
                providesTags:["usuariosUpdate"]
            }),
            save:builder.mutation({
                query:(_body)=>({
                    url:'Save',
                    method:'POST',
                    //responseType: "blob",
                    body:_body,
                    headers: {
                        'Subscription-Key': suscripcion_key, 
                      },

                }),
                invalidatesTags:["usuariosUpdate"],
            }),
        })

    }
    )
export const {
    useFindUserQuery,
    useGetAllUsersQuery,
    useSaveMutation
}=usuariosApi