import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";

export type pais = {
    codigo:string,
    nombre:string,
    inactivo:boolean,
    cultura:string,
    decimales:number,
    simbolo:string
};
type paises = {Paises:Array<pais>}
export type empresa = {
    codigo:string,
    nombre:string,
    inactivo:boolean,
    nombreDbSap:string,
    nomenclatura:string
    pais:pais,
    sync:any
}
type empresas = {Empresas:Array<empresa>}
export type articulo = {
    codigo:string,
    nombre:string,
    empresa:empresa,
    unidadMedida:string,
    grupo:string
}
type articulos = {Articulos:Array<articulo>}
export type catalogoGenerico={
    codigo:string,
    nombre:string
}
type cecoConfig = {
    grupoArticulos:string,
    centroCosto:string
}
type cecos = {
    CentrosCosto:Array<catalogoGenerico>,
    Config:Array<cecoConfig>
}
type servicios = {Servicios:Array<catalogoGenerico>}
type sites = {Sites:Array<catalogoGenerico>}
type cars = {CARs:Array<catalogoGenerico>}
type clientes = {Clientes:Array<catalogoGenerico>}
type compradores = {Compradores:Array<catalogoGenerico>}
type departamentos = {Departamentos:Array<catalogoGenerico>}
type prioridades = {Prioridades:Array<catalogoGenerico>}
export type tipoCompra = {
    codigo:string
    nombre:string
    tipoFlujo:string
}
type tiposCompra = { TiposCompra:Array<tipoCompra>}


export const catalogosApi = createApi({
    reducerPath:'CatalogosApi',
    baseQuery: fetchBaseQuery({baseUrl:process.env.REACT_APP_API_ASIENTOS_CONTABLES_CATALOGOS}),
    endpoints:(builder) =>({
        fetchPaises:builder.query<paises,string>({
            query:(mostrarInactivos:string='false')=>{
                let formData=new FormData()
                formData.append("IncluirInactivos",mostrarInactivos)
                return {
                    url:'GetPaises',
                    method:'POST',
                    body:formData
                }
            }
        }),
        fetchEmpresas:builder.query<empresas,FormData>({
                query:(_bodyFormData)=>{
                    if(_bodyFormData.get("IncluirInactivos")===null)
                        _bodyFormData.append("IncluirInactivos","false")
                    return{
                        url:'GetEmpresas',
                        method:'POST',
                        body:_bodyFormData
                    }
                }
            }),
        fetchArticulos:builder.query<articulos,FormData>({
                query:(_body:FormData=new FormData())=> {
                    return {
                        url:'GetArticulos',
                        method:'POST',
                        body:_body,
                        formData: true
                    }
                }
            }),
        fetchCeCos:builder.query<cecos,FormData>({
            query:(_body)=>({
                url:'GetCeCos',
                method:'POST',
                body:_body
            })
        }),
        fetchServicios:builder.query<servicios,FormData>({
            query:(_body)=>({
                url:'GetServicios',
                method:'POST',
                body:_body
            })
        }),
        fetchSites:builder.query<sites,FormData>({
            query:(_body)=>({
                url:'GetSites',
                method:'POST',
                body:_body
            })
        }),
        fetchCars:builder.query<cars,FormData>({
            query:(_body)=>({
                url:'GetCars',
                method:'POST',
                body:_body
            })
        }),
        fetchClientes:builder.query<clientes,FormData>({
            query:(_body)=>({
                url:'GetClientes',
                method:'POST',
                body:_body
            })
        }),
        fetchCompradores:builder.query<compradores,FormData>({
            query:(_body)=>({
                url:'GetCompradores',
                method:'POST',
                body:_body
            })
        }),
        fetchDepartamentos:builder.query<departamentos,void>({
            query:()=>({
                url:'GetDepartamentos',
                method:'POST'
            })
        }),
        fetchPrioridades:builder.query<prioridades,void>({
            query:()=>({
                url:'GetPrioridades',
                method:'POST'
            })
        }),
        fetchTiposCompra:builder.query<tiposCompra,void>({
            query:()=>({
                url:'GetTiposCompra',
                method:'POST'
            })
        })

    })

})
export const {
    useFetchPaisesQuery,
    useFetchEmpresasQuery,
    useFetchArticulosQuery,
    useFetchCeCosQuery,
    useFetchServiciosQuery,
    useFetchSitesQuery,
    useFetchCarsQuery,
    useFetchClientesQuery,
    useFetchCompradoresQuery,
    useFetchDepartamentosQuery,
    useFetchPrioridadesQuery,
    useLazyFetchTiposCompraQuery
} = catalogosApi