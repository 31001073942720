import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#fff",
    color: "#2a2b2c",
    hoverBg: "#f2f2f2",
    activeBg: "#25E2CC"
  },
  topbar: {
    bg: "#003D5B",
    color: "#fff"
  },
  mainBg: "#F2F2F2"
};

export default colorConfigs;