import React, {useEffect,useContext} from 'react';
import AsientoContableNuevo from '../asientos_contables/nuevo'

type Props = {};

const NuevoAsiento = (props: Props) => {
  return (

          <div>
              <AsientoContableNuevo/>
          </div>

  );
};

export default NuevoAsiento;