import React, {useEffect, useState} from "react";
import {utils,writeFile} from 'xlsx';
import {useNavigate} from "react-router-dom";
import {LoggedUser} from "../../configs/userTokenDecrypt";

import {Box, Button, CircularProgress, TextField, ThemeProvider} from "@mui/material";
import ResizableTable from "./components/ResizableTable";

import {pais, empresa, catalogosApi} from "../../redux/api/catalogos";
import {useGetAreasQuery,Area} from "../../redux/api/manageCases"
import {useLazyGetBandejaBusquedaAvanzadaQuery} from "../../redux/api/bandejas";
import Themex from "../../configs/theme";
import DatePickerSV from "../../components/inputs/date_picker";
import SearchSelect from "../../components/inputs/select";



const BusquedaAvanzada = () =>{
    const user=LoggedUser()
    const navigate = useNavigate()
    const fetchPaises = catalogosApi.endpoints.fetchPaises.useQuery("false")
    const [triggerEmpresas, fetchEmpresas] = catalogosApi.endpoints.fetchEmpresas.useLazyQuery()
    const fetchAreas=useGetAreasQuery()
    const [triggerBandeja,fetchBandeja]=useLazyGetBandejaBusquedaAvanzadaQuery()

    let fecha = new Date();

    //variables de busqueda
    const [fechaFin,setFechaFin]=useState<Date>(new Date(fecha));
    fecha.setMonth(fecha.getMonth() - 1)
    const [fechaInicial,setFechaInicial]=useState<Date>(new Date(fecha))
    const [numCaso,setNumCaso]=useState<number|undefined>()
    const [pais,setPais]=useState<pais|undefined>(undefined)
    const [empresa,setEmpresa]=useState<empresa|undefined>(undefined)
    const [solicitante,setSolicitante]=useState('')
    const [estados,setEstados]=useState<any[]>([])
    const [areas,setAreas]=useState<Area[]>([])

    const [fetchEstados] = useState({
        data:[
            {codigo:'P',nombre:'En Proceso'},
            {codigo:'A',nombre:'Completado'},
            {codigo:'R',nombre:'Rechazado'},
            {codigo:'E',nombre:'Error'},
        ],
        isLoading:false,
        isFetching:false,
        isUninitialized:false
    })

    useEffect(()=>{
        document.title = 'Asientos Contables - Bandeja Avanzada'
        cargarBandeja()
    },[])
    useEffect(()=>{
        if(!user?.permisos?.some(p=>p==="Reporteria"))
            navigate('/')   //si no tiene permisos de reporteria que sea redirigido a home
    },[user])
    useEffect(() => {
        setEmpresa(undefined)
        if (pais !== undefined) {
            let _form = new FormData();
            _form.append("pais", pais?.codigo ?? '')
            triggerEmpresas(_form)
        }
    }, [pais])

    const cargarBandeja=()=>{
        let data=new FormData()
        data.append('fecha_inicio',fechaInicial.toLocaleDateString())
        data.append('fecha_fin',fechaFin.toLocaleDateString())
        data.append('num_caso',''+(numCaso??0))
        data.append('pais',pais?.codigo??'')
        data.append('empresa',empresa?.codigo??'')
        data.append('area',areas.map(area => area.nombre).join(','))
        data.append('solicitante',solicitante)
        data.append('estado',estados.map(e=>e.codigo).join(','))
        triggerBandeja(data)
    }



    const handlerRowClick=(row:any)=>{
        navigate('/Editar/'+row.codigo)
    }

    const handlerChangeNumCaso = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        let parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            setNumCaso(parsedValue);
        } else {
            setNumCaso(undefined);
        }
    };

    function padZero(num:number) {
        return num < 10 ? '0' + num : num;
    }

    const handleDownloadExcel = () => {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}${padZero(currentDate.getMonth() + 1)}${padZero(currentDate.getDate())}_${padZero(currentDate.getHours())}${padZero(currentDate.getMinutes())}${padZero(currentDate.getSeconds())}`;
        let data=fetchBandeja.data?.Bandeja.map(
            ({codigo,Fecha,...resto})=>({
            fecha: new Date(Fecha).toLocaleDateString('es-SV', { day: '2-digit', month: '2-digit', year: 'numeric' }),
            ...resto
        }))??[]
        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws,  'busqueda');
        writeFile(wb, 'asientos_constables_'+formattedDate+'.xlsx');
    };

    return (
        <>
            <Box component="form"
                 autoComplete="off"
                 sx={{
                     '& .MuiTextField-root': {m: 2, width: '25ch'},
                     backgroundColor: 'white',
                     m: 1, borderRadius: '12px'
                 }}>
                <ThemeProvider theme={Themex}>
                    <DatePickerSV error={false} fecha={fechaInicial} handler={setFechaInicial} label={'Fecha Inicio'}
                                sx={{maxWidth:1/4,minWidth:1/4,m:2}}/>
                    <DatePickerSV error={false} fecha={fechaFin} handler={setFechaFin} label={'Fecha Fin'}
                                  sx={{maxWidth:1/4,minWidth:1/4,m:2}}/>
                    <TextField type="number" InputProps={{ inputProps: { min: 0} }} error={false}
                               label='Numero de Caso' value={numCaso} onChange={handlerChangeNumCaso}
                               variant='standard' sx={{maxWidth:1/4,minWidth:1/4,}}/>
                    <SearchSelect variable={pais} fetch={fetchPaises} label={'Pais'} lista={fetchPaises.data?.Paises}
                                    onSelect={(p:pais)=>setPais(p)} sx={{maxWidth:1/4,minWidth:1/4, m:2,zIndex:13,  position: 'relative'}}
                                    textoPredeterminado={'Todos'} limpiable={true}/>
                    <SearchSelect variable={empresa} fetch={fetchEmpresas} label={'Empresa'} lista={fetchEmpresas.data?.Empresas}
                                  onSelect={(e:empresa)=>setEmpresa(e)} sx={{maxWidth:1/4,minWidth:1/4, m:2,zIndex:12,  position: 'relative'}}
                                  textoPredeterminado={'Todas'} limpiable={true}/>
                    <SearchSelect variable={areas} fetch={fetchAreas} lista={fetchAreas.data?.Areas} label={'Areas'}
                                  onSelect={(a:Area[])=>setAreas(a)} multi={true} sx={{maxWidth:1/4,minWidth:1/4, m:2,zIndex:11,  position: 'relative'}}
                                  textoPredeterminado={'Todas'} limpiable={true}/>
                    <SearchSelect variable={estados} fetch={fetchEstados} lista={fetchEstados.data} label={'Estados'}
                                  onSelect={(e:any[])=>setEstados(e)} multi={true} sx={{maxWidth:1/5,minWidth:1/5, m:2,zIndex:10,  position: 'relative'}}
                                  textoPredeterminado={'Todos'} limpiable={true}/>
                    <Button variant="contained" sx={{m:2}} onClick={cargarBandeja}>Buscar</Button>
                    <Button variant="outlined" sx={{m:2}} onClick={handleDownloadExcel}>Descargar</Button>
                </ThemeProvider>

            </Box>
            {fetchBandeja.isLoading?<CircularProgress />:<ResizableTable data={fetchBandeja.data?.Bandeja??[]} rowClick={handlerRowClick}/>}
        </>
    )
}
export default BusquedaAvanzada
