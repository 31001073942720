import { Outlet } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import SideBar from "../common/SideBar";
import TopBar from "../common/TopBar";
import { useEffect } from "react";
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import userToken from "../../configs/userTokenDecrypt";
import {useFindUserQuery,Usuario} from "../../redux/api/usuarios";
import {drawerWidth} from "../../configs/design";
import theme from '../../configs/theme'
import {SnackbarProvider, VariantType, useSnackbar, closeSnackbar} from 'notistack';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const MainLayout = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(true);
    const [user, setUser] = React.useState<Usuario>();
    const [roles, setRoles] = React.useState<string[]|undefined>([]);

    let _userToken = new userToken();
    const _filtroUsuario=new FormData()
    _filtroUsuario.append('filter',"usuario")
    _filtroUsuario.append('param',_userToken.getEmail())

    const findUser=useFindUserQuery(_filtroUsuario)
    const { enqueueSnackbar } = useSnackbar();
    const mostrarMensajeInformativo = (mensaje:string,variante:VariantType='warning')=>{
        enqueueSnackbar(mensaje,{
            anchorOrigin: { vertical: 'top', horizontal: 'right'},
            variant: variante,
            action: (key) => (
                <IconButton onClick={() => { closeSnackbar(key) }}>
                    <CloseIcon />
                </IconButton>
            ) });
    }
    useEffect(() => {
      if(!findUser.isLoading&&!findUser.isFetching&&!findUser.isUninitialized&&user===undefined)
      {
          if(findUser.isError){
              setUser(undefined);
              setRoles([]);
              setAlerta("Usuario no existe en la aplicación, solamente en Azure B2C \n '" + _userToken.getEmail() + "'");
              setOpen(true);
          }
          if(findUser.isSuccess){
              if(findUser.data.Usuario.activo){
                  setUser(findUser.data.Usuario)
                  setRoles(findUser.data.Usuario?.roles?.split(','))
              }else{
                  setUser(findUser.data.Usuario);
                  mostrarMensajeInformativo('Usuario Inactivo')
                  setAlerta("Usuario inactivo \n '" + _userToken.getEmail() + "'")
                  setOpen(true);
                  setRoles([]);

              }
          }

      }
  }, [findUser,user,enqueueSnackbar]);


  const [openAlerta, setOpen] = React.useState(false);
  const [alerta, setAlerta] = React.useState("");

  const cerrarAlerta = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props,ref,) {
    return <MuiAlert elevation={16} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Box sx={{ display: "flex", overflow: "hidden" }} style={{ overflow: "hidden" }}>
      <TopBar roles={roles} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>
      <Box
        component="nav"
        sx={{
          width: drawerOpen?drawerWidth:`calc(${theme.spacing(7)} + 1px)`,
          flexShrink: 0,
            //overflowX: 'hidden',
            [theme.breakpoints.up('sm')]: {
                width: drawerOpen?drawerWidth:`calc(${theme.spacing(7)} + 1px)`,
            },
        }}
      >
        {user!==undefined && user?.activo == true && <SideBar roles={roles===undefined?[]:roles} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>}
      </Box>
      {(user?.activo === true && roles !== undefined) && <SnackbarProvider maxSnack={5}><Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: colorConfigs.mainBg,
            width: `calc(100% - ${drawerWidth}px)`,
            minHeight: "100vh",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box></SnackbarProvider>}
      <Snackbar style={{ left: "10%" }} open={openAlerta} autoHideDuration={4000} onClose={cerrarAlerta}>
        <Alert onClose={cerrarAlerta} severity="error" sx={{ width: '100%' }}>
          {alerta}
        </Alert>
      </Snackbar>

    </Box>
  );
};

export default MainLayout;