import { useParams } from 'react-router-dom';
import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {LoggedUser} from "../../configs/userTokenDecrypt";
import {
    useGetAsientoContableQuery,
    useSetAsientoContableVistoMutation,
    Etapa,
    useSetAsientoContableResultadoEtapaMutation, AsientoContable, useUpdateAsientoContableMutation
} from "../../redux/api/admin/asiento_contable";
import Themex from "../../configs/theme";
import {
    Box, Button,
    CircularProgress, FormControl, InputLabel,
    Paper, Stack,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {ThemeProvider} from '@mui/material/styles';
import Table from "@mui/material/Table";
import {anexo, manageCasesApi} from "../../redux/api/manageCases";
import TablaDetalles, {Detalle} from "./components/tablaDetalles";
import ResizableTable from "../bandeja/components/ResizableTable";
import {catalogosApi, pais,empresa} from "../../redux/api/catalogos";
import Combo from "./components/combo";
import {Gerencia, useGetAllGerenciasQuery, useLazyGetAprobadresEtapaQuery} from "../../redux/api/admin/gerencias";
import {closeSnackbar, useSnackbar, VariantType} from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {useDropzone} from "react-dropzone";
import {Container, DropZoneContainer, FileList, FileListContainer, StyledFile} from "../../configs/inputFileStyles";
import {LocalizationProvider} from "@mui/x-date-pickers";
import "dayjs/locale/es";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import dayjs from 'dayjs';

const AsientoContableEditar = () =>{
    let { asiento } = useParams();
    const estados: {[key: string]: string} = {
        'A': 'Aprobado',
        'E': 'Error',
        'P':'Pendiente',
        'R':'Rechazado',
        'S':'Reasignado',
        'O':'Observado'
    };
    const user=LoggedUser()
    const fetchAsientoContable=useGetAsientoContableQuery(asiento??'')
    const fetchPaises = catalogosApi.endpoints.fetchPaises.useQuery("true")
    const fetchDepartamentos = catalogosApi.endpoints.fetchDepartamentos.useQuery()
    const fetchPrioridades = catalogosApi.endpoints.fetchPrioridades.useQuery()
    const [triggerEmpresas, fetchEmpresas] = catalogosApi.endpoints.fetchEmpresas.useLazyQuery()
    const [triggerUpdateVistoEtapa]=useSetAsientoContableVistoMutation()
    const [fetchAcciones,] = useState({
        data:[
            {codigo:'A',nombre:'Aprobar'},
            {codigo:'O',nombre:'Observar'},
            {codigo:'R',nombre:'Rechazar'},
        ],
        isLoading:false,
        isFetching:false,
        isUninitialized:false
    })

    
    const [triggerAprobadores,fetchAprobadores] = useLazyGetAprobadresEtapaQuery()
    const [accion,setAccion]=useState<any>()
    const [comentarioEtapa,setComentarioEtapa]=useState<string>('')
    const [etapaActual,setEtapaActual]=useState<Etapa|undefined>()

    const [reasigancionDisponible,setReasignacionDisponible]=useState<boolean>(false)
    const [usuarioReasignacion,setUsuarioReasignacion]=useState<any>(undefined)
    const [comentarioReasignacion,setComentarioReasignacion]=useState<string>('')

    const [edicionDisponible,setEdicionDisponible]=useState<boolean>(false)
    const [anexos, setAnexos] = useState<anexo[]>([])
    const [detalles, setDetalles] = useState<Detalle[]>([])
    const [detalle,setDetalle]=useState<Detalle>({})
    const [detError,setDetError]=useState<any>([]);
    const [asientoContable,setAsientoContable]=useState<AsientoContable>({})

    const [triggerArticulos, fetchArticulos] = catalogosApi.endpoints.fetchArticulos.useLazyQuery()
    const [triggerCeCos, fetchCeCos] = catalogosApi.endpoints.fetchCeCos.useLazyQuery()
    const [triggerTiposCompra, fetchTiposCompra] = catalogosApi.endpoints.fetchTiposCompra.useLazyQuery()
    const [triggerComprador, fetchComprador] = catalogosApi.endpoints.fetchCompradores.useLazyQuery()
    const [triggerClientes, fetchClientes] = catalogosApi.endpoints.fetchClientes.useLazyQuery()
    const [triggerServicios, fetchServicios] = catalogosApi.endpoints.fetchServicios.useLazyQuery()
    const [triggerSites, fetchSites] = catalogosApi.endpoints.fetchSites.useLazyQuery()
    const [triggerCars, fetchCars] = catalogosApi.endpoints.fetchCars.useLazyQuery()
    var listaArticulos = [
        {
            "empresa": null,
            "codigo": "JE CODE",
            "nombre": "JE CODE",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "AP",
            "nombre": "AP",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "INTERCO",
            "nombre": "INTERCO",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "LEG",
            "nombre": "LEG",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "TRE",
            "nombre": "TRE",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "AR",
            "nombre": "AR",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "ASSETS",
            "nombre": "ASSETS",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "REC",
            "nombre": "REC",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "NOM",
            "nombre": "NOM",
            "unidadMedida": "",
            "grupo": ""
        },
        {
            "empresa": null,
            "codigo": "TAXES",
            "nombre": "TAXES",
            "unidadMedida": "",
            "grupo": ""
        },
    ];
    useEffect(() => {
        document.title = 'Asientos Contables - Editar'
    }, []);
    useEffect(() => {
        if(!fetchAsientoContable.isFetching&&fetchAsientoContable.isSuccess){
            let _form = new FormData();
            _form.append("pais", fetchAsientoContable.data?.AsientoContable?.pais ?? '')
            triggerEmpresas(_form)
            let etapaPendiente=fetchAsientoContable.data?.Etapas?.find(e=>e.estado==='P')
            if(etapaPendiente && etapaPendiente?.encargado===user?.usuario ){
                if(etapaPendiente.fecha_visto===null){
                    _form = new FormData();
                    _form.append("asiento_contable",asiento??'')
                    _form.append("gerencia",etapaPendiente.gerencia_codigo?.toString()??'')
                    _form.append("codigo",etapaPendiente.codigo?.toString()??'')
                    triggerUpdateVistoEtapa(_form)
                }

                setEdicionDisponible(etapaPendiente.etapa_codigo===1)
            }
            setEtapaActual(etapaPendiente)
            setReasignacionDisponible(false)//por si se recarga
            if(user)
                if(user?.roles?.split(',').includes('Administrador'))
                    if(etapaPendiente && (etapaPendiente?.etapa_codigo??0)>1)
                        setReasignacionDisponible(true)
            //copiar info por si es necesario editarla
            setAnexos([...fetchAsientoContable.data?.Anexos??[]])
            setDetalles([...fetchAsientoContable.data?.Detalles??[]])
            setAsientoContable({...fetchAsientoContable.data?.AsientoContable})
        }

    }, [fetchAsientoContable]);
    useEffect(()=>{
        if(reasigancionDisponible===true)
        {
            let _form=new FormData()
            _form.append('etapa',''+etapaActual?.etapa_codigo)
            _form.append('area',fetchAsientoContable.data?.AsientoContable?.area??'')
            triggerAprobadores(_form)
        }
    },[reasigancionDisponible])
    useEffect(()=>{
        if(edicionDisponible===true)
        {
            let _form = new FormData()
            _form.append("empresa", fetchAsientoContable.data?.AsientoContable?.empresa ?? '')
            triggerArticulos(_form);
            triggerCeCos(_form)
            triggerTiposCompra()//no recibe nada porque vienen de fw no de sap
            triggerComprador(_form)
            triggerClientes(_form)
            triggerServicios(_form)
            triggerSites(_form)
            triggerCars(_form)
            setDetalle({...detalle,fechaRequerida:dayjs(new Date()).toString()})
        }
    },[edicionDisponible])


    const getPais=()=>{
        if(fetchPaises.isFetching)
            return fetchAsientoContable.data?.AsientoContable?.pais
        else
            return fetchPaises.data?.Paises?.find((p:pais)=>p.codigo===fetchAsientoContable.data?.AsientoContable?.pais)?.nombre??''
    }
    const getEmpresa=()=>{
        if(fetchEmpresas.isFetching||!fetchEmpresas.isSuccess)
            return fetchAsientoContable.data?.AsientoContable?.empresa
        else
            return fetchEmpresas.data?.Empresas?.find((e:empresa)=>e.codigo===fetchAsientoContable.data?.AsientoContable?.empresa)?.nombre??''
    }
    const getDepartamento=()=>{
        if(fetchDepartamentos.isFetching)
            return fetchAsientoContable.data?.AsientoContable?.departamento
        else
            return fetchDepartamentos.data?.Departamentos?.find((d)=>d.codigo===fetchAsientoContable.data?.AsientoContable?.departamento)?.nombre??''
    }
    const getPrioridad=()=>{
        if(fetchPrioridades.isFetching)
            return fetchAsientoContable.data?.AsientoContable?.prioridad
        else
            return fetchPrioridades.data?.Prioridades?.find((d)=>d.codigo===fetchAsientoContable.data?.AsientoContable?.prioridad)?.nombre??''
    }
    const [cargando,setCargando]=useState(false)
    const [triggerResultadoEtapa]=useSetAsientoContableResultadoEtapaMutation()
    const handlerClickGuardar=()=>{
        if(accion){
            let _form = new FormData();
            _form.append("asiento_contable",asiento??'')
            _form.append("gerencia",etapaActual?.gerencia_codigo?.toString()??'')
            _form.append("codigo",etapaActual?.codigo?.toString()??'')
            _form.append("estado",accion.codigo??'')
            _form.append("comentario",comentarioEtapa??'')
            _form.append("usuario",user?.usuario??'')
            _form.append("etapa",etapaActual?.etapa_codigo?.toString()??'')
            setCargando(true)
            triggerResultadoEtapa(_form)
                .unwrap()
                .then(result=>{
                    console.log(result)
                    setCargando(false)
                })
                .catch((err)=>{
                    console.log(err)
                    setCargando(false)

                })
        }
    }
    const getAprobadoresIgnoreActual=()=>{
        if(reasigancionDisponible)
            return fetchAprobadores.data?.Aprobadores.filter((aprobador)=>aprobador.codigo!=etapaActual?.encargado)
        return fetchAprobadores.data?.Aprobadores
    }
    const handlerClickReasignar=()=>{

            let _form = new FormData();
            _form.append("asiento_contable",asiento??'')
            _form.append("gerencia",etapaActual?.gerencia_codigo?.toString()??'')
            _form.append("codigo",etapaActual?.codigo?.toString()??'')
            _form.append("estado",'S')//Reasignacion
            _form.append("comentario",(user?.usuario??'')+': '+(comentarioReasignacion??''))
            _form.append("usuario",usuarioReasignacion?.codigo??'')//usuario a quien se le reasigna
            _form.append("etapa",etapaActual?.etapa_codigo?.toString()??'')
            setCargando(true)
            triggerResultadoEtapa(_form)
                .unwrap()
                .then(result=>{
                    console.log(result)
                    setCargando(false)
                    setUsuarioReasignacion(undefined)
                    setComentarioReasignacion('')
                })
                .catch((err)=>{
                    console.log(err)
                    setCargando(false)

                })
    }
    const [triggerUpdateAsientoContable]=useUpdateAsientoContableMutation()
    const handlerClickGuardarCambios=()=>{
        if(detalles.length>0)
            if(anexos.length>0)
            {
                setCargando(true)
                let formData=new FormData()
                formData.append("AsientoContable",JSON.stringify(asientoContable))
                formData.append("Detalles",JSON.stringify(detalles))
                formData.append("Anexos",JSON.stringify(anexos))
                formData.append("Etapa",JSON.stringify(etapaActual))
                triggerUpdateAsientoContable(formData)
                    .unwrap()
                    .then((response)=> {
                            if(response){
                                mostrarMensajeInformativo('Caso guardado y enviado correctamente','success')
                                //navigate('/')
                                setEdicionDisponible(false)
                                setCargando(false)
                            }
                        }
                    ).catch((err)=>{
                    mostrarMensajeInformativo('Error al intentar guardar','error')
                    console.log(err)
                    setCargando(false)
                })


            }
            else
                mostrarMensajeInformativo('Faltan anexos')
        else
            mostrarMensajeInformativo('No se a agregado ningún articulo')
    }

    //metodos necearios para edicion
    const [error,setError]=useState<any>([]);
    const { enqueueSnackbar } = useSnackbar();
    const mostrarMensajeInformativo = (mensaje:string,variante:VariantType='warning')=>{
        enqueueSnackbar(mensaje,{
            anchorOrigin: { vertical: 'top', horizontal: 'right'},
            variant: variante,
            action: (key) => (
                <IconButton onClick={() => { closeSnackbar(key) }}>
                    <CloseIcon />
                </IconButton>
            ) });
    }

    //region anexos
    const [triggerAnexosUpload] = manageCasesApi.endpoints.anexosUpload.useMutation()
    const [triggerAnexosDownload] = manageCasesApi.endpoints.anexosDownload.useMutation()
    const [file, setFile] = useState<File[]>([]);
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files);
            setFile(filesArray);
        }
    };
    const setAnexoUpload = async (reg:anexo)=>{
        anexos.push(reg)
        setFile([])
    }
    const subirArchivo = () => {
        if (!file.length) {
            return;
        }

        file.forEach((fileItem) => {
            let formData = new FormData();
            formData.append("file", fileItem);
            triggerAnexosUpload(formData)
                .unwrap()
                .then((adjunto)=>{
                    setAnexoUpload(adjunto)
                })
                .catch(err=>console.log(err))


        });

    };
    const descargarArchivo = async(index: number) =>{

        if(anexos?.length<=index){
            return}

        const formData = new FormData()
        formData.append("archivo",JSON.stringify(anexos[index]))
        await triggerAnexosDownload(formData)
            .unwrap()
            .then((response)=>{
                const fileurl = window.URL.createObjectURL(response as Blob);
                const link = document.createElement("a");
                link.href = fileurl
                link.setAttribute('download', anexos[index].nombre);
                document.body.appendChild(link);
                link.click();
            })
            .catch((error)=>console.log(error))

    }
    const eliminarArchivo = (index:number)=>{
        let nuevosAnexos=[...anexos]
        nuevosAnexos.splice(index,1)
        setAnexos(nuevosAnexos)
    }
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setFile ((prevFiles) => [...prevFiles, ...acceptedFiles])
        },
        [file]
    );
    const {getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})
    const removeFile = (index: number) => {
        const newFiles = [...file];
        newFiles.splice(index,1)
        setFile(newFiles)

    }
    //end region anexos


    const handlerClickEliminarSeleccionLista=(seleccionados:Detalle[])=>{
        //si se carga desde base de datos la lista se deberia pedir confirmacion para eliminar
        let det2=detalles
        seleccionados.forEach(item=>{
            det2=det2.filter(deta=>deta!==item)
        })
        setDetalles(det2)

    }
    const handlerClickAgregar=()=>{
        const fechaActual = new Date();
        fechaActual.setHours(0, 0, 0, 0);
        let deta:Detalle=detalle
        let detaError:any = [];
        if(deta.articulo===undefined)   detaError.articulo=true
        if(deta.descripcion2==="")      detaError.descripcion2=true
        if((deta.cantidad??0)<=0)            detaError.cantidad=true
        // if(deta.tipoCompra===undefined) detaError.tipoCompra=true
        // if((new Date(String(deta.fechaRequerida))).getTime()<(fechaActual.getTime()))
        //     detaError.fechaRequerida=true
        // if(deta.comprador===undefined)  detaError.comprador=true
        // if(deta.centroCosto===undefined)detaError.centroCosto=true
        // if(deta.cliente===undefined)    detaError.cliente=true
        // if(deta.servicio===undefined)   detaError.servicio=true
        // if(deta.site===undefined)       detaError.site=true
        // if(deta.car===undefined)        detaError.car=true
        if(deta.gerencia===undefined)        detaError.gerencia=true
        //if(deta.comentario==="")        detaError.comentario=true //no se valida en fw

        if(Object.keys(detaError).length===0)
            setDetalles([...detalles,deta])

        console.log(detaError);
        setDetError(detaError)
    }
    const filtrarTipoCompra = () =>{
        if(fetchTiposCompra.isUninitialized||fetchTiposCompra.isFetching||fetchTiposCompra.isLoading){
            return undefined
        }
        if(detalle.articulo!==undefined){
            let _cod=detalle.articulo?.codigo.substring(0, 5)
            let tipoCompraFiltrado
            if (_cod === "ARTFJ") {
                tipoCompraFiltrado=fetchTiposCompra.data?.TiposCompra.filter(tp=>{return tp.tipoFlujo==="C"})
            }
            else if (_cod === "ARTPV") {
                tipoCompraFiltrado=fetchTiposCompra.data?.TiposCompra.filter(tp=>{return tp.tipoFlujo!=="C"})
            }

            return tipoCompraFiltrado
        }else{
            return fetchTiposCompra.data?.TiposCompra
        }
    }
    const filtrarCeCos = () => {
        if(fetchTiposCompra.isUninitialized||fetchTiposCompra.isFetching||fetchTiposCompra.isLoading)
            return undefined

        if(detalle.articulo===undefined)
            return fetchCeCos.data?.CentrosCosto

        let config=fetchCeCos.data?.Config.filter(c=>{return c.grupoArticulos===detalle.articulo?.grupo})
        if(config?.length===0)
            return fetchCeCos.data?.CentrosCosto.filter(cc=>cc.codigo==='ZZ991')
        else
            return fetchCeCos.data?.CentrosCosto.filter(cc=> {
                    return !!config?.find(c => c.centroCosto === cc.codigo);
                }
            )
    }
    const filtrarCliente = () => {
        if (fetchClientes.isUninitialized || fetchClientes.isFetching || fetchClientes.isLoading)
            return undefined

        if (detalle.centroCosto === undefined)
            return fetchClientes.data?.Clientes

        //retorna los clientes que inicien con 1 si el ceco inicia 1
        //retorna los clientes que inicien en 2,3 o codigo 0000 si el ceco inicia con 2
        //si el ceco no inicia en 1 o 2, se retorna la lista completa
        if(detalle.centroCosto?.codigo.substring(0, 1) === '1')
            return fetchClientes.data?.Clientes.filter(c=>c.codigo.substring(0, 1) ==='1')
        else if(detalle.centroCosto?.codigo.substring(0, 1) === '2')
            return fetchClientes.data?.Clientes.filter(c=>c.codigo.substring(0, 1) ==='2' || c.codigo.substring(0, 1) ==='3' || c.codigo==='0000')
        else
            return fetchClientes.data?.Clientes

    }
    let _filtroGerencias = new FormData()
    _filtroGerencias.append("soloActivos", 'true')
    const fetchGerencias = useGetAllGerenciasQuery(_filtroGerencias)
    const filtrarGerencia = () =>{
        if(fetchGerencias.isUninitialized||fetchGerencias.isFetching||fetchGerencias.isLoading)
            return undefined

        if(fetchAsientoContable.data?.AsientoContable?.pais!==undefined)
            return fetchGerencias.data?.Gerencias.filter(g=>g.pais===fetchAsientoContable.data?.AsientoContable?.pais&&g.activo===true)

        return fetchGerencias.data?.Gerencias
    }

    return (
        <>
            {fetchAsientoContable.isFetching?<CircularProgress />:(
                    <div style={{  width: "100%" }}>
                <Box component="form"
                     autoComplete="off"
                     sx={{
                         '& .MuiTextField-root': {m: 2, width: '25ch'},
                         backgroundColor: 'white',
                         m: 1, borderRadius: '10px'
                     }}
                >
                    <ThemeProvider theme={Themex}>
                        <Typography variant="h6" sx={{my: 1, borderRadius: '4px',m:1}}>
                            Datos Generales
                        </Typography>
                        <TextField label="Caso" variant="standard" value={fetchAsientoContable.data?.AsientoContable?.numero} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                        <TextField label="Fecha" variant="standard" value={fetchAsientoContable.data?.AsientoContable?.fecha?new Date(fetchAsientoContable.data?.AsientoContable?.fecha).toLocaleDateString('es-SV'):''} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                        <TextField label="Area" variant="standard" value={fetchAsientoContable.data?.AsientoContable?.area} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                        <TextField label="Departamento" variant="standard" value={getDepartamento()} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                        <TextField label="Solicitante" variant="standard" value={fetchAsientoContable.data?.AsientoContable?.solicitante} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                        <TextField label="País" variant="standard" value={getPais()} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                        <TextField label="Empresa" variant="standard" value={getEmpresa()} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                        <TextField label="Prioridad" variant="standard" value={getPrioridad()} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                        <TextField label="Descripción General" variant="standard" value={fetchAsientoContable.data?.AsientoContable?.descripcion} InputProps={{readOnly: true,}} sx={{minWidth:4/5}}/>
                        <TextField label="Monto" variant="standard" value={fetchAsientoContable.data?.AsientoContable?.monto} InputProps={{readOnly: true,}} sx={{minWidth:1/5}}/>
                    </ThemeProvider>
                </Box>
                        <Box component="form"
                             autoComplete="off"
                             sx={{
                                 '& .MuiTextField-root': {m: 2, width: '25ch'},
                                 backgroundColor: 'white',
                                 m: 1, borderRadius: '10px'
                             }}
                        >
                            <ThemeProvider theme={Themex}>
                                <Typography variant="h6" sx={{my: 1, borderRadius: '4px',m:1}}>
                                    Adjuntos
                                </Typography>
                                {edicionDisponible?(
                                    <Container>
                                        <DropZoneContainer {...getRootProps()}>
                                            <input onChange={handleFileChange} {...getInputProps()}/>
                                            {isDragActive ? (
                                                <p>Suelte los archivos aquí...</p>
                                            ) : (
                                                <p >Pulse aqui para agregar archivos</p>
                                            )}
                                        </DropZoneContainer>

                                        {file.length > 0 && (
                                            <FileListContainer>
                                                <FileList>
                                                    {file.map((file, index) =>(
                                                        <StyledFile key={"asiento_contable_nuevo_anexo-"+index}>
                                                            {file.name}
                                                            <Button variant="contained" color='secondary' sx={{m:1}} onClick={() => removeFile(index)}>
                                                                Eliminar
                                                            </Button>

                                                        </StyledFile>
                                                    ))}
                                                </FileList>
                                                <Button variant="contained" sx={{m:2}} onClick={subirArchivo} >Subir Archivos</Button>
                                            </FileListContainer>
                                        )}
                                    </Container>
                                ):(<></>)}
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableBody>
                                            {anexos.map((row,index) => (
                                                <TableRow
                                                    key={"asiento_contable_nuevo_anexo_"+row.guid}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" sx={{width:'80%'}}>
                                                        {row.nombre}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        <Button  variant="contained" component="label" onClick={()=>descargarArchivo(index)}>Descargar</Button>
                                                    </TableCell>
                                                    {edicionDisponible?(
                                                        <TableCell align="right">
                                                            <Button  variant="contained"  color='secondary' component="label" onClick={()=>eliminarArchivo(index)}>Eliminar</Button>
                                                        </TableCell>
                                                    ):(<></>)}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </ThemeProvider>
                        </Box>
                        {edicionDisponible?(
                            <Box component="form"
                                 autoComplete="off"
                                 sx={{
                                     '& .MuiTextField-root': {m: 2, width: '25ch'},
                                     backgroundColor: 'white',
                                     m: 1, borderRadius: '12px'
                                 }}>
                                <ThemeProvider theme={Themex}>
                                    <Typography variant="h6" sx={{my: 1, borderRadius: '4px',m:1}}>
                                        Detalle Asiento Contable
                                    </Typography>

                                    <Combo label={'Tipo Journal'} fetch={undefined} variable={detalle.articulo} error={detError.articulo}
                                           lista={listaArticulos} onSelect={(a:any)=>setDetalle({...detalle,articulo:a})}
                                           sx={{minWidth: "40%", m: 2}}/>


                                    <TextField  variant='standard' sx={{minWidth: "40%",m:2}} error={detError.descripcion2}
                                                label='Descripción 2' value={detalle.descripcion2} onChange={({target:{value}}:any)=>{setDetalle({...detalle,descripcion2:value})}}/>
                                    <TextField type="number" InputProps={{ inputProps: { min: 0} }} error={detError.cantidad}
                                               label='Cantidad' value={detalle.cantidad} onChange={({target:{value}}:any)=>{setDetalle({...detalle,cantidad:value})}}
                                               variant='standard' sx={{minWidth: "5%",maxWidth:"5%"}}/>
                                    {/* <Combo label={'Tipo de Compra'}  fetch={fetchTiposCompra} variable={detalle.tipoCompra} error={detError.tipoCompra}
                                           lista={filtrarTipoCompra()} onSelect={(a:any)=>setDetalle({...detalle,tipoCompra:a})}
                                           sx={{minWidth: '20%', m: 1, width: '25ch'}}/> */}
                                    {/* <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
                                        <FormControl sx={{m:1}}>
                                            <InputLabel required size={"normal"} variant={"standard"} shrink={true} error={detError.fechaRequerida}>Fecha Requerida</InputLabel>
                                            <DemoContainer components={['DatePicker']} sx={{padding:"0 0 0 0"}}>
                                                <DatePicker
                                                    label=""
                                                    value={detalle.fechaRequerida ? dayjs(new Date(String(detalle.fechaRequerida))) : dayjs(new Date())}
                                                    onChange={(date: dayjs.Dayjs | null)=>{if(date){setDetalle({...detalle,fechaRequerida:date.toString()})}}}
                                                    format={'DD/MM/YYYY'}
                                                    sx={{fieldset:{
                                                            borderTop:"transparent",
                                                            borderLeft:"transparent",
                                                            borderRight:"transparent",
                                                            borderBottom:detError.fechaRequerida?"red":"primary.main"
                                                        },
                                                        input:{
                                                            boxSizing: "inherit"
                                                        }}}

                                                />
                                            </DemoContainer>
                                        </FormControl>
                                    </LocalizationProvider> */}
                                    {/* <Combo label={'Solicitante'} fetch={fetchComprador} variable={detalle.comprador} error={detError.comprador}
                                           lista={fetchComprador.data?.Compradores} onSelect={(a:any)=>setDetalle({...detalle,comprador:a})}
                                           sx={{minWidth: '35%', m: 1, width: '25ch'}} mostrarCodigo={false}/> */}
                                    {/* <Combo label={'Centro de Costo'} fetch={fetchCeCos} variable={detalle.centroCosto}  error={detError.centroCosto}
                                           lista={filtrarCeCos()} onSelect={(a:any)=>setDetalle({...detalle,centroCosto:a})}
                                           sx={{minWidth: '20%', m: 1, width: '25ch'}}/>
                                    <Combo label={'Cliente'} fetch={fetchClientes} variable={detalle.cliente} error={detError.cliente}
                                           lista={filtrarCliente()} onSelect={(a:any)=>setDetalle({...detalle,cliente:a})}
                                           sx={{minWidth: '25%', m: 1, width: '25ch'}}/>
                                    <Combo label={'Servicio'} fetch={fetchServicios} variable={detalle.servicio} error={detError.servicio}
                                           lista={fetchServicios.data?.Servicios} onSelect={(a:any)=>setDetalle({...detalle,servicio:a})}
                                           sx={{minWidth: '25%', m: 1, width: '25ch'}}/>
                                    <Combo label={'Site'} fetch={fetchSites} variable={detalle.site} error={detError.site}
                                           lista={fetchSites.data?.Sites} onSelect={(a:any)=>setDetalle({...detalle,site:a})}
                                           sx={{minWidth: '20%', m: 1, width: '25ch'}}/>
                                    <Combo label={'CAR'} fetch={fetchCars} variable={detalle.car} error={detError.car}
                                           lista={fetchCars.data?.CARs} onSelect={(a:any)=>setDetalle({...detalle,car:a})}
                                           sx={{minWidth: '25%', m: 1, width: '25ch'}}/> */}
                                    <Combo label={'Gerencia'} fetch={fetchGerencias} variable={detalle.gerencia} error={detError.gerencia}
                                           lista={filtrarGerencia()} onSelect={(a:any)=>setDetalle({...detalle,gerencia:a})}
                                           sx={{minWidth: '25%', m: 1, width: '25ch'}}/>
                                    {/* <TextField  variant='standard' sx={{minWidth: 1/3}} error={detError.comentario}
                                                label='Comentario de linea' value={detalle.comentario} onChange={({target:{value}}:any)=>{setDetalle({...detalle,comentario:value})}}/>
                                    <TextField  variant='standard' sx={{minWidth: 1/6}} InputProps={{readOnly: true,} }
                                                label='Unidad de medida' value={detalle.articulo?.unidadMedida??' '} */}

                                    <Button variant="contained" startIcon={<AddIcon />} sx={{m:2}} onClick={handlerClickAgregar}>Agregar</Button>
                                </ThemeProvider>
                            </Box>
                        ):(<></>)}
                        <Box>
                            <TablaDetalles detalles={detalles} eliminarSeleccionados={handlerClickEliminarSeleccionLista} deleteAllow={edicionDisponible}/>
                        </Box>
                        {(etapaActual?.encargado===user?.usuario&& !edicionDisponible) ?(
                            <Box component="form" autoComplete="off" sx={{
                                                                       '& .MuiTextField-root': {m: 2, width: '25ch'},
                                                                       backgroundColor: 'white',
                                                                       m: 1, borderRadius: '10px'}}>
                            <ThemeProvider theme={Themex}>
                                <Typography variant="h6" sx={{my: 1, borderRadius: '4px',m:1}}>
                                    Acciones de Etapa
                                </Typography>
                                    <Box component="form" sx={{with:'100%'}}>
                                        <Combo variable={accion} fetch={fetchAcciones} lista={fetchAcciones.data} label={'Accion'}
                                               onSelect={(a:any)=>setAccion(a)} mostrarCodigo={false}
                                               sx={{minWidth: 1/5, m: 2,maxWidth:'30ch',zIndex:8}}/>
                                        <TextField label="Comentario" variant="standard" value={comentarioEtapa} onChange={(event:any)=>setComentarioEtapa(event.target.value)} InputProps={{readOnly: false,}} sx={{minWidth:3/5,m:1}}/>

                                    </Box>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Button variant="contained" disabled={cargando} onClick={handlerClickGuardar}>Guardar</Button>
                                </Box>

                            </ThemeProvider>
                        </Box>):(<></>)}
                        {reasigancionDisponible?(
                            <Box component="form" autoComplete="off" sx={{
                                '& .MuiTextField-root': {m: 2, width: '25ch'},
                                backgroundColor: 'white',
                                m: 1, borderRadius: '10px'}}>
                                <ThemeProvider theme={Themex}>
                                    <Typography variant="h6" sx={{my: 1, borderRadius: '4px',m:1}}>
                                        Reasignación
                                    </Typography>
                                    <Box component="form" sx={{with:'100%'}}>
                                        <Combo variable={usuarioReasignacion} fetch={fetchAprobadores} lista={getAprobadoresIgnoreActual()} label={'Aprobadores'}
                                               onSelect={(a:any)=>setUsuarioReasignacion(a)} mostrarCodigo={true}
                                               sx={{minWidth: 2/5, m: 2,maxWidth:'30ch',zIndex:7}}/>
                                        <TextField label="Comentario" variant="standard" value={comentarioReasignacion} onChange={(event:any)=>setComentarioReasignacion(event.target.value)}
                                                   InputProps={{readOnly: false,}} sx={{minWidth:2/5,m:2}}/>

                                    </Box>
                                    <Box  display="flex" justifyContent="center" alignItems="center">
                                        <Button variant="contained" disabled={cargando} onClick={handlerClickReasignar}>Reasignar</Button>
                                    </Box>
                                </ThemeProvider>
                            </Box>
                        ):(<></>)}
                        {edicionDisponible?(
                            <Box component="form"
                                 autoComplete="off"
                                 sx={{
                                     '& .MuiTextField-root': {m: 2, width: '25ch'},
                                     backgroundColor: 'white',
                                     m: 1, borderRadius: '12px'
                                 }}>
                                <ThemeProvider theme={Themex}>
                                    <Typography variant="h6" sx={{my: 1, borderRadius: '4px',m:1}}>
                                        Acciones Solicitante
                                    </Typography>
                                    <TextField label="Comentarios" variant="standard" value={asientoContable.comentario}
                                               InputProps={{readOnly: false,}} sx={{minWidth:8/9}}
                                               onChange={(event:any)=>setAsientoContable({...asientoContable,comentario:event.target.value})}
                                    />
                                    <Button variant="contained" sx={{m:2}} onClick={handlerClickGuardarCambios} disabled={cargando}>Guardar y Enviar</Button>
                                </ThemeProvider>
                            </Box>
                        ):(<></>)}
                        <Box component="form"
                             autoComplete="off"
                             sx={{
                                 '& .MuiTextField-root': {m: 2, width: '25ch'},
                                 backgroundColor: 'white',
                                 m: 1, borderRadius: '10px'
                             }}>
                            <ThemeProvider theme={Themex}>
                                <Typography variant="h6" sx={{my: 1, borderRadius: '4px',m:1}}>
                                    Historico de Etapas
                                </Typography>
                                <Box display="block"
                                     justifyContent="center"
                                     alignItems="center">
                                    <ResizableTable rowClick={()=>{}} data={[...(fetchAsientoContable.data?.Etapas??[])].map((etapa:any)=>{
                                        let newRow={
                                            Etapa:etapa.etapa_nombre??'',
                                            Encargado:etapa.encargado_nombre??'',
                                            Estado:estados[etapa.estado],
                                            Creación:new Date(etapa.fecha_creacion).toLocaleString('es-SV'),
                                            Visto:etapa.fecha_visto ? new Date(etapa.fecha_visto).toLocaleString('es-SV'):'',
                                            Completado:etapa.fecha_completado ? new Date(etapa.fecha_completado).toLocaleString('es-SV'):'',
                                            Comentario:etapa.comentario
                                        }
                                        return newRow })}/>
                                </Box>

                            </ThemeProvider>
                        </Box>
                    </div>
            )}
        </>

    )
}
export default AsientoContableEditar;