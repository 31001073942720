import React, {useState,useEffect} from "react";
import {CssBaseline, Divider, List, useTheme} from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemGeneric from "./SidebarItemGeneric";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import {Drawer,DrawerHeader} from '../../configs/design'
import {RouteType} from "../../routes/config";
import logo from '../../assets/images/logo-concentrix.png'
type Props = {
    roles: string[],
    drawerOpen:boolean,
    setDrawerOpen:React.Dispatch<React.SetStateAction<boolean>>
};
const SideBar = ({roles,drawerOpen,setDrawerOpen}: Props) => {
    const theme = useTheme();
    const handleDrawerClose = () => setDrawerOpen(false)


    const cerrarSesion = async () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace(process.env.REACT_APP_B2C_LOGIN!);
    };

  return (
    <Drawer
      variant="permanent"
      open={drawerOpen}
      
      /*sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sizeConfigs.sidebar.width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
          top: "65px"
         
        }
      }}*/
    >
        <DrawerHeader>
            <img src="https://staprovisionesdev.blob.core.windows.net/login/CNX2024_Logo.png" alt={"logo"} style={{width:"175px", marginTop:"15px", marginBottom:"15px",backgroundColor:"white"}}/>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
        </DrawerHeader>
        <Divider />
        <CssBaseline />

        <List disablePadding>

          { roles.length > 0 && appRoutes.map((route, index) => (
            route.sidebarProps ? (
              (route.config == null && (roles.some(x => x === route.roles) || route.roles === "")) ? (
                <SidebarItem item={route} key={index} open={drawerOpen} />
              ) : (
                null
              )
            ) : null
          ))}
        </List>


        {roles.length > 0 && appRoutes.map((route, index) => (

          route.sidebarProps ? (
            (route.config && roles.some(x => x === route.roles)) ? (
              <SidebarItemGeneric item={route} key={index} open={drawerOpen} />
            ) : (
              null
            )
          ) : null
        ))}
        
       


      <div onClick={cerrarSesion}>

          <SidebarItemGeneric item={{
            path: " ",
            element: " ",
            state: " ",
            sidebarProps: {
              displayText: "Cerrar Sesión",
              icon: <ExitToAppIcon fontSize="large" />
            },

          }} key={99} open={drawerOpen} />
        </div>
    </Drawer>
  );
};

export default SideBar;