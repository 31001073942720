import jwt_decode from "jwt-decode";
import {useFindUserQuery, Usuario} from "../redux/api/usuarios";

export default class UserTokenDecrypt {

  name: string = "";
  emails: string = "";

  constructor() {

    let jsontoken = [];
    let sesion = sessionStorage.getItem("jsontoken") || "";
    let _jsontoken: string = JSON.stringify(jwt_decode(sesion));

    jsontoken = JSON.parse(_jsontoken);

    this.name = jsontoken["name"];
    this.emails = jsontoken["emails"][0];
  }

  getName(): string {
    return this.name;
  }
  getEmail(): string {
    return this.emails;
  }

}

/**
 * Obtiene el usuario autenticado actualmente.
 * @returns {Usuario | undefined} El usuario autenticado, o undefined si no hay usuario autenticado.
 */
export const LoggedUser = ():Usuario|undefined => {
  let _userToken = new UserTokenDecrypt();
  const _filtroUsuario=new FormData()
  _filtroUsuario.append('filter',"usuario")
  _filtroUsuario.append('param',_userToken.getEmail())
  const findUser=useFindUserQuery(_filtroUsuario)
  if (!findUser.isLoading && !findUser.isFetching && !findUser.isUninitialized) {
    if (findUser.isError || !findUser.data?.Usuario)
      return undefined;
    if (findUser.isSuccess)
      return findUser.data?.Usuario
  }
  return undefined;
};