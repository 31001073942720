import {anexo, manageCasesApi} from '../manageCases'
import {Detalle} from "../../../pages/asientos_contables/components/tablaDetalles";
export type AsientoContable={
    codigo?:string,
    numero?:number,
    solicitante?:string,
    fecha?:string
    pais?:string
    empresa?:string
    area?:string
    departamento?:string,
    prioridad?:string,
    descripcion?:string
    comentario?:string
    monto?:string
}
type AsientoContableData={
    AsientoContable?:AsientoContable
    Detalles:Array<Detalle>
    Anexos:Array<anexo>
    Etapas:Array<Etapa>
}
export type Etapa={
    gerencia_codigo?:string
    gerencia_nombre?:string
    codigo?:number
    etapa_codigo?:number
    etapa_nombre?:string
    encargado?:string
    encargado_nombre?:string
    fecha_creacion?:Date
    fecha_visto?:Date
    fecha_completado?:Date
    estado?:string
}
let suscripcion_key = process.env.REACT_APP_SUSCRIPCION_KEY;
const asientosContablesApi = manageCasesApi.injectEndpoints({
    endpoints:(builder)=>({
        saveAsientoContable:builder.mutation({
            query:(_body)=>({
                url:'AsientosContables/Insert',
                method:'POST',
                body:_body,
                headers: {
                    'Subscription-Key': suscripcion_key, 
                  },
            }),
            invalidatesTags:["bandeja"]
        }),
        updateAsientoContable:builder.mutation({
            query:(_body)=>({
                url:'AsientosContables/Update',
                method:'POST',
                body:_body,
                headers: {
                    'Subscription-Key': suscripcion_key, 
                  },
            }),
            invalidatesTags:["bandeja"]
        }),
        getAsientoContable:builder.query<AsientoContableData,string>({
            query:(codigo)=>({
                url:`AsientosContables/${codigo}?subscription-key=${suscripcion_key}`,
                method:'GET',
            }),
            providesTags:["bandeja"]
        }),
        setAsientoContableVisto:builder.mutation({
            query:(_body)=>({
                url:'AsientosContables/Etapa/Visto',
                method:'POST',
                body:_body,
                headers: {
                    'Subscription-Key': suscripcion_key, 
                  },
            }),
            invalidatesTags:["bandeja"]
        }),
        setAsientoContableResultadoEtapa:builder.mutation({
            query:(_body)=>({
                url:'AsientosContables/Etapa/Actualizar',
                method:'POST',
                body:_body,
                headers: {
                    'Subscription-Key': suscripcion_key, 
                  },
            }),
            invalidatesTags:["bandeja"]
        })
    })
})
export const {
    useSaveAsientoContableMutation,
    useGetAsientoContableQuery,
    useSetAsientoContableVistoMutation,
    useSetAsientoContableResultadoEtapaMutation,
    useUpdateAsientoContableMutation
}=asientosContablesApi