import { manageCasesApi } from './manageCases'

export type bandeja ={
    codigo:string
    Fecha:Date
    descripcion:string
    numero:number
    pais:string
    empresa:string
    departamento:string
    prioridad:string

}
let suscripcion_key = process.env.REACT_APP_SUSCRIPCION_KEY;
const bandejasApi =manageCasesApi.injectEndpoints({

    endpoints: (build) => ({
        getBandejaEnviados: build.query<{Bandeja:Array<bandeja>},string>({
            query: (solicitante) => ({
                url:`Bandeja/Enviados/${solicitante}?subscription-key=${suscripcion_key}`,
                method:'GET',
            }),
            providesTags:["bandeja"]
        }),
        getBandejaEntrada: build.query<{Bandeja:Array<bandeja>},string>({
            query: (solicitante) => ({
                url:`Bandeja/Entrada/${solicitante}?subscription-key=${suscripcion_key}`,
                method:'GET',
            }),
            providesTags:["bandeja"]
        }),
        getBandejaBusquedaAvanzada: build.query<{Bandeja:Array<bandeja>},FormData>({
            query:(body)=>({
                url:'Bandeja/Avanzada',
                method:'POST',
                body:body,
                headers: {
                    'Subscription-Key': suscripcion_key, 
                  },
            }),
            providesTags:["bandeja"]
        }),

    }),
    overrideExisting: false,
})
export const {
    useGetBandejaEnviadosQuery,
    useGetBandejaEntradaQuery,
    useLazyGetBandejaBusquedaAvanzadaQuery
}=bandejasApi