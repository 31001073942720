import * as React from 'react'
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

import type { catalogoGenerico, articulo, tipoCompra } from "../../../redux/api/catalogos";
import { Gerencia } from "../../../redux/api/admin/gerencias";

export interface Detalle {
    codigo?: number,
    articulo?: articulo,
    descripcion2?: string,
    cantidad?: number,
    tipoCompra?: tipoCompra,
    fechaRequerida?: string,
    comprador?: catalogoGenerico,
    centroCosto?: catalogoGenerico,
    cliente?: catalogoGenerico,
    servicio?: catalogoGenerico,
    site?: catalogoGenerico,
    car?: catalogoGenerico,
    comentario?: string
    gerencia?: Gerencia
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Detalle;
    label: string;
    numeric: boolean;
    mw: string
}
const headCells: readonly HeadCell[] = [
    {
        id: 'articulo',
        numeric: false,
        disablePadding: true,
        label: 'Tipo Journal',
        mw: "300"
    },
    {
        id: 'descripcion2',
        numeric: false,
        disablePadding: false,
        label: 'Descripción',
        mw: "100"
    },
    {
        id: 'cantidad',
        numeric: true,
        disablePadding: false,
        label: 'Cantidad',
        mw: "50"
    },
    {
        id: 'gerencia',
        numeric: true,
        disablePadding: false,
        label: 'Gerencia',
        mw: "50"
    },
    // {
    //     id: 'comprador',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Comprador',
    //     mw:"100"
    //  },

];
type Order = 'asc' | 'desc';
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}


interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Detalle) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Detalle) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead>
            <TableRow key={"asiento_contable_nuevo_tabla_detalles_header_row"}>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={"asiento_contable_nuevo_tabla_detalles_header_row_cell-" + index}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ minWidth: headCell.mw }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    handleDeleteSelectedRows: any
    deleteAllowed?: boolean
}
function EnhancedTableToolbar(props: EnhancedTableToolbarProps & { deleteAllowed?: boolean }) {
    const { handleDeleteSelectedRows, numSelected, deleteAllowed = true } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: "#d5d5d5",
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                    
                >
                    {numSelected} seleccionados
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    Detalle de Asiento Contable
                </Typography>
            )}
            {(numSelected > 0 && deleteAllowed) ? (
                <Tooltip title="Eliminar">
                    <IconButton onClick={handleDeleteSelectedRows}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
}
type props = {
    detalles: Detalle[],
    eliminarSeleccionados: (seleccion: Detalle[]) => void
    deleteAllow?: boolean
}
const TablaDetalles = ({ detalles, eliminarSeleccionados, deleteAllow = true }: props) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Detalle>('articulo');
    const [selected, setSelected] = React.useState<Detalle[]>([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Detalle,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = detalles.map((n) => n);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, element: Detalle) => {
        const selectedIndex = selected.indexOf(element);
        let newSelected: Detalle[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, element);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (id: Detalle) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - detalles.length) : 0;

    function compare(a: Detalle, b: Detalle) {

        if (orderBy != undefined && (typeof a[orderBy] === 'string' || a[orderBy] instanceof String))
            return String(a[orderBy]) > String(b[orderBy]) ?
                order == "asc" ? 1 : -1 : order == "desc" ? 1 : -1
        if (orderBy != undefined && (typeof a[orderBy] === 'number' || a[orderBy] instanceof Number))
            return Number(a[orderBy]) > Number(b[orderBy]) ?
                order == "asc" ? 1 : -1 : order == "desc" ? 1 : -1
        if (orderBy != undefined && a[orderBy]?.hasOwnProperty("codigo")) {
            let ac = a[orderBy] as articulo | catalogoGenerico | tipoCompra
            let bc = b[orderBy] as articulo | catalogoGenerico | tipoCompra
            return String(ac.codigo) > String(bc.codigo) ? order == "asc" ? 1 : -1 : order == "desc" ? 1 : -1
        }
        return 1;
    }
    const visibleRows = React.useMemo(
        () =>
            detalles?.sort((a, b) => compare(a, b)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [order, orderBy, page, rowsPerPage, detalles],
    );
    const handleDeleteSelectedRows = () => {
        eliminarSeleccionados(selected)
        setSelected([])//clear selection
    }
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} handleDeleteSelectedRows={handleDeleteSelectedRows} deleteAllowed={deleteAllow} />
                <TableContainer>
                    <Table
                        key={"asiento-contable-nuevo-tabla-detalles"}
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='medium'
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={detalles.length}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = isSelected(row);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={"asiento_contable_nuevo_tabla_detalles_row_" + index}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer'}}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>

                                        <TableCell component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none" sx={{ minWidth: 400 }}
                                        >{row.articulo?.nombre}</TableCell>
                                        <TableCell component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none" sx={{ minWidth: 400 }}
                                        >{row.articulo?.nombre}</TableCell>
                                        <TableCell align="center"  sx={{ minWidth: 50 }}>{row.cantidad}</TableCell>

                                        <TableCell align="right" sx={{ minWidth: 200 }}>{row.gerencia?.codigo + ' - ' + row.gerencia?.nombre}</TableCell>

                                        {/* <TableCell sx={{ minWidth: 200 }}>{row.comprador?.nombre}</TableCell>  */}


                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    key={"asiento_contable_nuevo_tabla_detalles_row_empty" + emptyRows}
                                    style={{
                                        height: 53 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={detalles.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={"Filas por pagina"}
                    labelDisplayedRows={(props) => `mostrando del ${props.from} al ${props.to} de ${props.count} articulos`}
                />
            </Paper>

        </Box>
    )
}
export default TablaDetalles;