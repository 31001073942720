import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {FormControl, InputLabel} from "@mui/material";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {LocalizationProvider} from "@mui/x-date-pickers";
import React from "react";
import dayjs, { Dayjs } from 'dayjs';
import {SxProps} from "@mui/system/styleFunctionSx";
import {Theme} from "@mui/system/createTheme";
type props={
    error:boolean,
    fecha:Date
    handler:(date: Date) => void;
    label?:string,
    obligatorio?:boolean
    sx?: SxProps<Theme>
}
const DatePickerSV = ({error,fecha,handler,label='Fecha',obligatorio=false,sx}:props)=>{
    const handleDateChange = (date: dayjs.Dayjs | null) => {
        if (date) {
            handler(date.toDate());
        }
    };
    return (
        <>
            <LocalizationProvider adapterLocale="es" dateAdapter={AdapterDayjs}>
                <FormControl sx={sx}>
                    <InputLabel required={obligatorio} size={"normal"} variant={"standard"} shrink={true} error={error}>{label}</InputLabel>
                    <DemoContainer components={['DatePicker']} sx={{padding:"0 0 0 0"}}>
                        <DatePicker
                            label=""
                            value={dayjs(fecha.toDateString())}
                            onChange={handleDateChange}
                            format={'DD/MM/YYYY'}
                            sx={{fieldset:{
                                    borderTop:"transparent",
                                    borderLeft:"transparent",
                                    borderRight:"transparent",
                                    borderBottom:error?"#cc3262":"primary.main"
                                },
                                input:{
                                    boxSizing: "inherit"
                                }}}

                        />
                    </DemoContainer>
                </FormControl>
            </LocalizationProvider>
        </>
    )
}
export default DatePickerSV