import { RouteType } from "./config";

import Home from "../pages/home";

import SyncCatalogos from "../pages/catalogos/SyncCatalogos";
import SyncIcon from '@mui/icons-material/Sync';

import NuevoAsiento from "../pages/nuevo_asiento/NuevoAsiento";
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import AsientoContableEditar from "../pages/asientos_contables/editar"


import Enviados from "../pages/bandeja/enviados"
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import Entrada from "../pages/bandeja/entrada";
import ArchiveIcon from '@mui/icons-material/Archive';
import BusquedaAvanzada from "../pages/bandeja/busqueda_avanzada";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import Usuarios from "../pages/usuarios";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import Gerencias from "../pages/gerencias"
import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <Home/>,
    state: "home"
  },
  {
    path:'*',
    element:<Home/>,
    state:'default'
  },
  {
    path: "/BandejaEntrada",
    element:  <Entrada/>,
    state: "Entrada",
    sidebarProps: {
      displayText: "Entrada",
      icon: <ArchiveIcon fontSize="large" />
    },
    roles: "Aprobador"
  },
  {
    path: "/BandejaEnviados",
    element:  <Enviados/>,
    state: "Enviados",
    sidebarProps: {
      displayText: "Enviados",
      icon: <UnarchiveIcon fontSize="large" />
    },
    roles: "Solicitante"
  },
  {
    path: "/BusquedaAvanzada",
    element:  <BusquedaAvanzada/>,
    state: "Busqueda Avanzada",
    sidebarProps: {
      displayText: "Busq. Avanzada",
      icon: <ManageSearchIcon fontSize="large" />
    },
    roles: "Reporteria"
  },

  {
    path: "/Nuevo",
    element:  <NuevoAsiento/>,
    state: "Nuevo",
    sidebarProps: {
      displayText: "Nuevo",
      icon: <NoteAddIcon fontSize="large" />
    },
    roles: "Solicitante"
  },
  {
    path: "/Editar/:asiento",
    element:  <AsientoContableEditar/>,
    state: "Editar",
    sidebarProps: {
      displayText: "Editar",
      icon: <NoteAddIcon fontSize="large" />
    },
    roles: "Solicitante,Aprobador"
  },
  {
    path: "/Catalogos",
    element: <SyncCatalogos />,
    state: "Catalogos",
    sidebarProps: {
      displayText: "Catálogos",
      icon: <SyncIcon fontSize="large"/>
    },
    roles: "Solicitante"
  },
  {
    path: "/Usuarios",
    element: <Usuarios />,
    state: "Usuarios",
    sidebarProps: {
      displayText: "Usuarios",
      icon: <ManageAccountsIcon fontSize="large"/>
    },
    roles: "Administrador"
  },
  {
    path: "/Gerencias",
    element: <Gerencias />,
    state: "Gerencias",
    sidebarProps: {
      displayText: "Gerencias",
      icon: <ReduceCapacityIcon fontSize="large"/>
    },
    roles: "Administrador"
  },

];

export default appRoutes;