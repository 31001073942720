import {Avatar, IconButton, Toolbar, Typography} from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import user from "../../configs/userTokenDecrypt";
import MenuIcon from '@mui/icons-material/Menu';
import {AppBar} from '../../configs/design'

type Props = {
  roles: string[]|undefined,
  drawerOpen:boolean,
  setDrawerOpen:React.Dispatch<React.SetStateAction<boolean>>
};
const TopBar = ({roles,drawerOpen,setDrawerOpen}: Props) => {
  let _userToken = new user();
  const handleDrawerOpen = () => setDrawerOpen(true)

  return (
    <AppBar
      position="fixed"
      open={drawerOpen}
      sx={{
          backgroundColor: colorConfigs.topbar.bg,
          color: colorConfigs.topbar.color
      }}
      /*sx={{
        width: `100%`,
        ml: sizeConfigs.sidebar.width,
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color,
        height: "65px"
      }}*/

    >
      <Toolbar>
          <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                  marginRight: 5,
                  ...(drawerOpen && { display: 'none' }),
              }}
          >
              <MenuIcon />
          </IconButton>

        <div style={{ position: "absolute", right: "3%" }}>

          <div style={{ cursor: "pointer", textAlign: "center" }} >
            <Typography fontSize={16}>
              {_userToken.getName()}

            </Typography>
            <Typography fontSize={12}>
              {roles?.toString()}
            </Typography>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;