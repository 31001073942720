import {Box, Typography,TextField,Button} from "@mui/material";
import {List,ListItem,ListItemButton,ListItemIcon,Checkbox,ListItemText} from "@mui/material";
import {Stack,FormControlLabel} from "@mui/material"
import {useSaveMutation} from "../../redux/api/usuarios"

import {ThemeProvider} from '@mui/material/styles';
import Themex from '../../configs/theme'
import React, {useEffect, useState} from "react";
import Combo from "../asientos_contables/components/combo";
import {Usuario} from "../../redux/api/usuarios";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    bgcolor: 'background.paper',
    border: 'transparent',
    boxShadow: '0px 2px 24px #383838',
    //height: "80%",
    padding: '16px 32px 24px 32px',
    textAlign: "left",

};

type props={
    nuevo:boolean,
    usuarios:Array<Usuario>,
    actualizar?:Usuario,
    admin:string,
    onClose:any
}
const Editar=React.forwardRef(({nuevo,usuarios,actualizar,admin,onClose}:props,ref)=>{
    const [fetchArea,] = useState({
        data:[
            {codigo:1,nombre:'Nómina'},
            {codigo:2,nombre:'Contabilidad'},
            {codigo:3,nombre:'Tesoreria'},
        ],
        isLoading:false,
        isFetching:false,
        isUninitialized:false
    })
    const roles=["Solicitante", "Aprobador", "Administrador", "Reporteria"]

    const [usuario,setUsuario] = useState<string>()
    const [nombre,setNombre] = useState<string>()
    const [area,setArea] = useState<any>()
    const [permisos,setPermisos] = useState<Array<string>>([])
    const [activo,setActivo]=useState(true)

    useEffect(()=>{
        if(!nuevo){
            setUsuario(actualizar?.usuario)
            setNombre(actualizar?.nombre)
            fetchArea.data.map((a)=>{
                if(a.nombre===actualizar?.area)
                    setArea(a)
            })
            setPermisos(actualizar?.roles?.split(',')??[])
            setActivo(actualizar?.activo??true)

        }
    },[nuevo,actualizar])
    const handlerSelectArea = (a:any) => setArea(a)
    const handlerSelectRol = (r:string) => {
        let _permisos=[...permisos]
        if(permisos.indexOf(r)===-1)
            _permisos.push(r)
        else
            _permisos.splice(permisos.indexOf(r),1)
        setPermisos(_permisos)
    }

    const isEmail = (email: string) =>/^[a-zA-Z0-9._+]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(email);
    let emails=usuarios.map(user=>user.usuario)
    const validEmail=(email:string) => {
        if(!nuevo)
            return true
        if(isEmail(email)){
            if(emails.indexOf(email)>=0)
                return false//ya existe el email en el listado
            return true;
        }
         return false;
    }
    const makePass = (length: number) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$%@/_-';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return (result);
    }
    const [error,setError]=useState<any>([]);
    const [triggerSave]=useSaveMutation()
    const [cargando,setCargando]=useState(false)
    const handlerClickGuardar=()=>{
        let newUser:Usuario={
            usuario:usuario??'',
            nombre:nombre??'',
            activo:activo,
            area:area?.nombre,
            roles:permisos.toString()
        }
        if(!nuevo)
            newUser.codigo=actualizar?.codigo
        else {
            newUser.contra = makePass(12)
            newUser.codigo=0;
        }

        let _error:any = []
        if(newUser.usuario==='' || !validEmail(newUser.usuario)) _error.usuario=true
        if(newUser.nombre==='') _error.nombre=true
        if(newUser.area===undefined)  _error.area=true
        if(Object.keys(_error).length===0){
            console.log("no errors, trying save..")
            //console.log(JSON.stringify(newUser))
            setCargando(true)
            let formData = new FormData();
            formData.append("nuevoUsuario", JSON.stringify(newUser))
            formData.append("usuario",admin)
            triggerSave(formData)
                .unwrap()
                .then((response)=>{
                    console.log(response)
                    setCargando(false)
                    if(response.ok)
                        onClose()
                })
        }

        setError(_error)
    }
    return (

        <Box sx={style} ref={ref}>
            <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign: 'center'}}>
                    {nuevo===true?"Crear Nuevo Usuario":"Editar Usuario"}
                </Typography>

                    <ThemeProvider theme={Themex}>
                        <Stack spacing={{ xs: 1, sm: 2}} direction="row" useFlexGap flexWrap="wrap" divider={
                            <Box
                                component="hr"
                                sx={{m:0,
                                    border: (theme:any) =>
                                        `1px solid ${theme.palette.primary.main}`,
                                }}/>}
                            >
                            <Box component="form" autoComplete="off" sx={{}}>
                                <TextField label="Nombre" variant="standard" value={nombre}  InputProps={{readOnly: false,}} sx={{minWidth:5/5,maxWidth:'30ch'}}
                                           error={error.nombre}
                                           onChange={(evt)=>setNombre(evt.target.value)}
                                />
                                <TextField label="Usuario" variant="standard" value={usuario} InputProps={{readOnly: false,}} sx={{minWidth:5/5,maxWidth:'30ch'}}
                                           error={!(validEmail(usuario ?? ''))}
                                           disabled={!nuevo}
                                           onChange={(evt)=>setUsuario(evt.target.value)}
                                />
                                <FormControlLabel control={<Checkbox checked={activo} onClick={()=>setActivo(!activo)}/>} label="Activo" />
                                <Combo variable={area} fetch={fetchArea} lista={fetchArea.data} label={'Area'}
                                       onSelect={handlerSelectArea} mostrarCodigo={false}
                                       error={error.area}
                                       sx={{minWidth: 5/5, m: 1,maxWidth:'30ch',paddingLeft:'-2rem'}}/>
                            </Box>
                            <Box>
                                <Typography>Roles</Typography>
                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                    {roles.map((rol,index)=>{
                                        const labelId = `checkbox-list-label-${rol}`
                                        return (
                                            <ListItem key={index} disablePadding>
                                                <ListItemButton role={undefined} onClick={()=>handlerSelectRol(rol)} dense>
                                                    <ListItemIcon>
                                                        <Checkbox
                                                            edge="start"
                                                            checked={permisos.indexOf(rol) !== -1}
                                                            tabIndex={-1}
                                                            disableRipple
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText id={labelId} primary={rol} />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Box>

                        </Stack>
                        <Box>
                            <Button variant="contained" disabled={cargando} onClick={handlerClickGuardar}>Guardar</Button>
                        </Box>
                    </ThemeProvider>

            </Box>

        </Box>
    )
})
export default Editar