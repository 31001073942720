import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import {Button, IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AccordionActions from "@mui/material/AccordionActions";
import Combo from "../../../asientos_contables/components/combo";
import {Usuario} from "../../../../redux/api/usuarios";
import Accordion from "@mui/material/Accordion";
import React, {useState} from "react";
import {Area, Etapa} from "../../../../redux/api/manageCases";
import {Aprobador} from "../../../../redux/api/admin/gerencias";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
type props={
    area:Area,
    etapa:Etapa,
    aprobadores:Array<Aprobador>
    fetchUsers: any,
    addAprobador:(area:string,etapa:number,aprobador:string)=>void
    removeAprobador:(area:string,etapa:number,aprobador:string)=>void
}
export const EtapaAccordion=({area,etapa,aprobadores,fetchUsers,addAprobador,removeAprobador}:props)=>{
    const [user,setUser]=useState<Usuario>()

    let UsuariosFaltantes = () => {
        let _aprobadores=aprobadores.find(aprob=>aprob.area==area.codigo&&aprob.etapa==etapa.codigo)?.aprobadores?.split(',')
        return fetchUsers.data?.Usuarios.filter((us:Usuario)=>!_aprobadores?.includes(us.usuario)&& us.roles?.split(',').includes('Aprobador')).map(({codigo,usuario,nombre}:Usuario)=>({codigo:codigo,nombre:nombre+" ("+usuario+")",usuario:usuario}))
    }
    let handlerClickAgregarAprobador=()=>{
        addAprobador(area.codigo,etapa.codigo,user?.usuario??'')
        setUser(undefined)
    }
    let handlerClickEliminarAprobador = (aprobador:string) => {
        removeAprobador(area.codigo,etapa.codigo,aprobador)
    }

    return (
        <>
            <Accordion key={"as_g_a_"+area.codigo+"_"+etapa.codigo} >
                <AccordionSummary key={"as_g_as_"+area.codigo+"_"+etapa.codigo}
                                  expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    {etapa.nombre}
                </AccordionSummary>
                <AccordionDetails key={"as_g_ad_"+area.codigo+"_"+etapa.codigo}>
                    <Grid container spacing={1/2} key={"as_g_as_d_"+area.codigo+"_"+etapa.codigo}>
                        {
                            aprobadores.find(aprob=>aprob.area==area.codigo&&aprob.etapa==etapa.codigo)?.aprobadores?.split(',')
                                .filter(value=>value!='')
                            .map((userMail,index)=>{
                                return (
                                    <Grid item xs="auto" key={"as_g_ad_g_item_"+area.codigo+"_"+etapa.codigo+"-"+index}>
                                        <Item>
                                            {fetchUsers.data?.Usuarios.find((us:Usuario)=>us.usuario==userMail)?.nombre+" ("+userMail+")"}
                                            <IconButton aria-label="Eliminar" color="secondary"
                                                        onClick={()=>handlerClickEliminarAprobador(userMail)}
                                            >
                                                <DeleteIcon fontSize="small"/>
                                            </IconButton>
                                        </Item>

                                    </Grid>
                                )
                            })
                        }

                    </Grid>
                </AccordionDetails>
                <AccordionActions>
                    <Combo variable={user} fetch={fetchUsers} label={'Usuario'} lista={UsuariosFaltantes()} onSelect={(a:Usuario)=>setUser(a)}
                           sx={{minWidth: 1/5, m: 1/2, width: '59ch',maxHeight:'12ch'}} mostrarCodigo={false}/>
                    <Button onClick={handlerClickAgregarAprobador}>Agregar</Button>
                </AccordionActions>
            </Accordion>
        </>
    )
}