import styled from "@emotion/styled";

export const Container = styled.div`
    border: 2px dashed #cccccc;
    padding: 1px;
    text-align : center
    `;

export const DropZoneContainer = styled.div `
    border: 2px dashed; 
    padding: 5px; 
    text-align: left;
    margin-bottom: 5px`
export const FileListContainer = styled.div`
margin-top: 3px`


export const FileList = styled. ul`
    list-style: none;
    margin: 0px 0px 0px 0px;
  text-align: left;
    padding :0`;
export const StyledFile = styled.li `
margin-bottom : 6px;
    `