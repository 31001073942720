import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Usuario} from "../../../redux/api/usuarios"
interface Column {
    id: 'nombre' | 'usuario'| 'activo'| 'area' | 'roles';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'nombre', label: 'Nombre', minWidth: 170 },
    { id: 'usuario', label: 'Usuario', minWidth: 100 },
    { id:'activo',label:'Estado', minWidth:50},
    { id: 'area', label: 'Area',minWidth: 170},
    { id: 'roles', label: 'Roles', minWidth: 170},
];

type props={usuarios:Array<Usuario>,
    handlerUpdateUser:any}
export default function TablaUsuarios({usuarios,handlerUpdateUser}:props) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const handleRowClick = (element:Usuario) =>{
        handlerUpdateUser(element)
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <TableContainer sx={{ maxHeight: 'auto' }}>
    <Table stickyHeader aria-label="sticky table">
        <TableHead>
            <TableRow>
                {columns.map((column) => (
                        <TableCell
                            key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
>
    {column.label}
    </TableCell>
))}
    </TableRow>
    </TableHead>
    <TableBody>
    {usuarios
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
                return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo} onClick={()=>handleRowClick(row)}>
                    {columns.map((column) => {
                            const value = row[column.id];
                            return (
                                <TableCell key={column.id} align={column.align}>
                                {column.id==='activo'?(row.activo?'Activo':'Inactivo'):
                                    column.format && typeof value === 'number'
                                        ? column.format(value)
                                        : value}
                                </TableCell>
                        );
                        })}

                    </TableRow>
            );
            })}
    </TableBody>
    </Table>
    </TableContainer>
    <TablePagination
    rowsPerPageOptions={[10, 25, 100]}
    component="div"
    count={usuarios.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    />
    </Paper>
);
}