import ResizableTable from './components/ResizableTable'
import {useGetBandejaEnviadosQuery} from "../../redux/api/bandejas";
import {useEffect} from "react";
import {CircularProgress} from "@mui/material";
import {LoggedUser} from "../../configs/userTokenDecrypt";
import { useNavigate } from 'react-router-dom';

const Enviados = () => {
    const user=LoggedUser()
    const fetchBandeja = useGetBandejaEnviadosQuery(user?.usuario??'')
    const navigate = useNavigate();
    useEffect(()=>{
        document.title = 'Asientos Contables - Bandeja Enviados'
    },[])
    const handlerClick=(row:any)=>{
        navigate('/Editar/'+row.codigo)
    }
    return (
        <div>
            {fetchBandeja.isLoading?<CircularProgress />:<ResizableTable data={fetchBandeja.data?.Bandeja??[]} rowClick={handlerClick}/>}


        </div>
    )
}
export default Enviados