import Select from "react-select"
import { FormControl, InputLabel } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx";
import { Theme } from "@mui/system/createTheme";


type props = {
    variable: any,
    fetch: any,
    lista?: any,
    trigger?: any,
    mostrarCodigo?: boolean,
    onSelect?: any,
    label: string,
    sx?: SxProps<Theme>,
    loadCallback?: any
    error?: boolean,
    isDisable?: boolean,
    multi?: boolean,
    requerido?: boolean
    limpiable?: boolean,
    textoPredeterminado?: string
}
const SearchSelect = ({ lista, variable, mostrarCodigo = false, fetch, onSelect, label, sx, error = false,
    isDisable = false, multi = false, requerido = false, limpiable = false,
    textoPredeterminado = 'Seleccione' }: props) => {

    const handlerSelect = (value: any) => {
        onSelect(value)
    }
    return (
        <>
            <FormControl sx={sx}>
                <InputLabel required={requerido} size={"normal"} variant={"standard"} shrink={true} error={error} sx={{ zIndex: 1 }}>{label}</InputLabel>
                <Select
                    value={variable ?? []}
                    options={lista}
                    isMulti={multi}
                    isLoading={fetch.isLoading || fetch.isFetching || fetch.isUninitialized}
                    getOptionLabel={(e) => (mostrarCodigo ? (e.codigo + ' - ') : '') + e.nombre}
                    getOptionValue={(e) => e.codigo}
                    noOptionsMessage={() => 'No hay datos'}
                    loadingMessage={() => 'Cargando'}
                    placeholder={textoPredeterminado}
                    onChange={(item) => handlerSelect(item)}
                    isDisabled={isDisable}
                    isClearable={limpiable}

                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderTop: "transparent",
                            borderLeft: "transparent",
                            borderRight: "transparent",
                            borderBottomColor: error ? 'red' : 'transparent',
                            boxShadow: 'none',
                            marginTop: '14px',
                            ":hover": { borderBottomColor: error ? '#cc3262' : '#2ed8c3' }
                        }),
                        multiValue:
                        (baseStyles, state) => ({
                            ...baseStyles,
                            backgroundColor: "#d5d5d5",
                        })
                        ,


                        option: (base, props) => ({
                            ...base,
                            backgroundColor: props.isSelected ? "#014751" : (props.isFocused ? "#2ed8c3" : "white"),
                            color: props.isSelected ? "white" : "black",
                            ':active': {
                                ...base[':active'],
                                backgroundColor: !props.isDisabled
                                    ? props.isSelected
                                        ? "orangered"
                                        : "orange"
                                    : undefined,

                            }
                        }),





                    }}
                />
            </FormControl>
        </>
    )
}
export default SearchSelect