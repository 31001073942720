import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";


export type anexo = {
    nombre:string,
    guid:string
    eliminado?:boolean
}
export type Area = {
    codigo:string,
    nombre:string
}
export type Etapa = {
    codigo:number
    nombre:string
}

let suscripcion_key = process.env.REACT_APP_SUSCRIPCION_KEY;

export const manageCasesApi = createApi(
    {
        reducerPath:'ManageCasesApi',
        baseQuery: fetchBaseQuery({baseUrl:process.env.REACT_APP_API_ASIENTOS_CONTABLES_MANAGE_CASES}),
        tagTypes:["gerencias","bandeja"],
        endpoints:(builder)=>({
            anexosUpload:builder.mutation< anexo ,FormData>({
                query:(_body)=>({
                    url:'Anexos/Upload',
                    method:'POST',
                    body:_body, //recibe file a subir,
                    headers: {
                        'Subscription-Key': suscripcion_key, 
                      },
                })
            }),
            //tiene que ser mutation porque los query solo retornan json
            anexosDownload:builder.mutation({
                query:(_body)=>({
                    url:'Anexos/Download',
                    method:'POST',
                    responseType: "blob",
                    body:_body,
                    cache: "no-cache",
                    headers: {
                        'Subscription-Key': suscripcion_key, 
                      },
                    responseHandler: (response) => {
                        return response.blob()
                    }
                })
            }),
            getAreas: builder.query<{Areas:Array<Area>},void>({
                query: () => ({
                    url:`Areas/List?subscription-key=${suscripcion_key}`,
                    method:'GET',
                }),
            }),
            getEtapas: builder.query<{Etapas:Array<Etapa> },void>({
                query: () => ({
                    url:`Etapas/List?subscription-key=${suscripcion_key}`,
                    method:'GET',
                }),
            }),

        })

    }
)
export const {
    useAnexosDownloadMutation,
    useAnexosUploadMutation,
    useGetAreasQuery,useGetEtapasQuery
}=manageCasesApi