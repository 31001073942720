import { manageCasesApi } from '../manageCases'
import {catalogoGenerico} from "../catalogos";
export type Gerencia={
    codigo?:string,
    nombre?:string,
    pais?:string,
    activo?:boolean,
    areas?:string
}
export type Aprobador={
    area?:string,
    etapa?:number,
    aprobadores?:string
}
let suscripcion_key = process.env.REACT_APP_SUSCRIPCION_KEY;
export type gerencias={Gerencias:Array<Gerencia>}
const gerenciasApi =manageCasesApi.injectEndpoints({

    endpoints: (build) => ({
        getAllGerencias: build.query<gerencias,FormData>({
            query: (_body) => ({
                url:'Gerencias/List',
                method:'POST',
                body:_body,
                headers: {
                    'Subscription-Key': suscripcion_key, 
                  },
            }),
            providesTags:["gerencias"]
        }),
        getAprobadores: build.query<{Aprobadores:Array<Aprobador>},string>({
            query: (gerencia:string) => ({
                url:`Gerencias/Aprobadores/${gerencia}?subscription-key=${suscripcion_key}`,
                method:'GET',
            }),
            providesTags:["gerencias"]
        }),
        getAprobadresEtapa:build.query<{Aprobadores:catalogoGenerico[]},FormData>({
            query:(body)=>({
                url:'Aprobadores/Etapa',
                method:'POST',
                body:body,
                headers: {
                    'Subscription-Key': suscripcion_key, 
                  },
            }),
            providesTags:["gerencias"]
        }),
        saveGerencia: build.mutation({
            query:(_body)=>({
                url:'Gerencias/Save',
                method:'POST',
                body:_body,
                headers: {
                    'Subscription-Key': suscripcion_key, 
                  },
            }),
            invalidatesTags:["gerencias"],
        }),
    }),
    overrideExisting: false,
})
export const {
    useGetAllGerenciasQuery,
    useLazyGetAprobadoresQuery,
    useSaveGerenciaMutation,
    useLazyGetAprobadresEtapaQuery
}=gerenciasApi