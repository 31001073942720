import React, {useEffect, useState} from "react";
import {Button, Modal} from "@mui/material";

import userToken from "../../configs/userTokenDecrypt";
import {useFindUserQuery, useGetAllUsersQuery, Usuario} from "../../redux/api/usuarios";
import {Gerencia,useGetAllGerenciasQuery} from "../../redux/api/admin/gerencias";
import {catalogosApi} from "../../redux/api/catalogos";
import {useGetAreasQuery,useGetEtapasQuery} from "../../redux/api/manageCases";

import List from "./components/list"
import Edit from "./components/edit";
import Editar from "./editar"



const Gerencias = () => {
    //cargando informacion de usuario logeado
    let _userToken = new userToken();
    let _filtroUsuario=new FormData()
    _filtroUsuario.append('filter',"usuario")
    _filtroUsuario.append('param',_userToken.getEmail())
    let fetchUser=useFindUserQuery(_filtroUsuario)
    const [isNew,setIsNew]=useState(true)
    const [update,setUpdate]=useState<Gerencia>()
    const [usuario,setUsuario] = useState<Usuario>()
    const [modalVisible,setModalVisible] =useState(false)

    let _filtroGerencias=new FormData()
    _filtroGerencias.append("soloActivos",'false')
    let fetchGerencias=useGetAllGerenciasQuery(_filtroGerencias)
    let fetchPaises = catalogosApi.endpoints.fetchPaises.useQuery("true")
    let fetchAreas = useGetAreasQuery()
    let fetchEtapas = useGetEtapasQuery()
    let _filtroUsuarios=new FormData()
    _filtroUsuarios.append('IncluirInactivos','false')
    let fetchUsers=useGetAllUsersQuery(_filtroUsuarios)
    let handlerUpdate = (gerencia:Gerencia)=>{
        setUpdate(gerencia)
        setIsNew(false)
        setModalVisible(true)
    }
    let handlerNew = () => {
        setIsNew(true)
        setModalVisible(true)
    }

    useEffect(()=>{
        document.title = 'Asientos Contables - Gerencias'
        if(fetchUser.isSuccess){
            setUsuario(fetchUser.data.Usuario)
        }
        //console.log(fetchGerencias)

    },[])

    return (
        <div>

            <Button onClick={handlerNew}>Nueva</Button>
            <List
                gerencias={fetchGerencias.data?.Gerencias??[]}
                handlerUpdate={handlerUpdate}
                paises={fetchPaises.data?.Paises??[]}
            />
            <Modal open={modalVisible} onClose={()=>setModalVisible(false)}
                   aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                <Editar
                    nuevo={isNew}
                    actualizar={update}
                    gerencias={fetchGerencias.data?.Gerencias??[]}
                    admin={usuario?.usuario??""}
                    fetchPaises={fetchPaises}
                    fetchAreas={fetchAreas}
                    fetchEtapas={fetchEtapas}
                    onClose={()=>setModalVisible(false)}
                    fetchUsuarios={fetchUsers}
                />
            </Modal>
        </div>
    )

}
export default Gerencias;