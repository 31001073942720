import React, {useEffect, useState} from "react";
import userToken, {LoggedUser} from "../../configs/userTokenDecrypt";
import {useFindUserQuery,useGetAllUsersQuery, Usuario} from "../../redux/api/usuarios";
import {Button} from "@mui/material";
import Modal from '@mui/material/Modal';

import TablaUsuarios from "./components/tablaUsuarios"
import EditarUsuario from "./editar"
import {useNavigate} from "react-router-dom";


const Usuarios = () => {
    const navigate = useNavigate();
    //cargando listado de usuarios
    let _filtroUsuarios=new FormData()
    _filtroUsuarios.append('IncluirInactivos','true')
    let fetchAllUsers=useGetAllUsersQuery(_filtroUsuarios,{refetchOnFocus:true})

    //modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setNuevo(true)
        setOpen(true)
    }
    const handleClose = () => setOpen(false);
    const [update,setUpdate] = useState<Usuario>()
    const [nuevo,setNuevo]=useState(true)
    const handlerUpdateUsuario = (updateUser:Usuario)=>{
        setUpdate(updateUser)
        setNuevo(false)
        setOpen(true)
    }
    const usuario=LoggedUser()
    useEffect(()=>{
        document.title = 'Asientos Contables - Usuarios'
        if(usuario)
            if(usuario?.permisos?.some(p=>p==='Administrador')){
                console.log('bienvenido administrador')
            }else{
                console.log('usted no deberia estar aqui')
                navigate('/')
            }
        },[usuario])



    return (
        <div>
                <Button onClick={handleOpen}>Nuevo Usuario</Button>
                <TablaUsuarios usuarios={fetchAllUsers.data?.Usuarios??[]} handlerUpdateUser={handlerUpdateUsuario}/>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <EditarUsuario
                        nuevo={nuevo}
                        usuarios={fetchAllUsers.data?.Usuarios??[]}
                        admin={usuario?.usuario??''}
                        actualizar={update}
                        onClose={handleClose}
                    />
                </Modal>


        </div>
)
}
export default Usuarios