import React, {useEffect, useState} from 'react';
import { Table } from 'rsuite';
import {SortType} from "rsuite-table";
import Pagination from '@mui/material/Pagination';
import 'rsuite-table/dist/css/rsuite-table.css';
import './CustomTable.css'
//import 'rsuite/dist/styles/rsuite-default.css'; // Asegúrate de importar los estilos de rsuite
const { Column, HeaderCell, Cell } = Table;


interface Props {
    data: any[]; // Array de objetos
    rowClick:any
}
const ResizableTable:  React.FC<Props> = ({ data ,rowClick}) => {
    const [page, setPage] = useState(1);
    const rowsPerPage =  Math.floor(window.innerHeight / 75);
    const [sortColumn, setSortColumn] = useState<string|undefined>();
    const [sortType, setSortType] = useState<SortType|undefined>();

    const handleSortColumn = (dataKey: string, sortType?: SortType | undefined) => {
        setSortColumn(dataKey);
        setSortType(sortType);
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };
    const columnWith: { [key: string]: number } ={
        País:100,
        Empresa:240,
        Caso:75,
        Detalle:250,
        Area:150,
        Departamento:150,
        Estado:125,
        Etapa:125,
        Comentario:600
    }
    const columns = Object.keys(data[0] || {}).map((key, index) => {
        if( key.toLowerCase() === 'fecha')
            return (
                <Column key={index} minWidth={100} resizable sortable>
                    <HeaderCell>{key}</HeaderCell>
                    <Cell align={"right"} dataKey={key}>{(rowData: any) => new Date(rowData[key]).toLocaleDateString()}</Cell>
                </Column>
            )
        if (key !== 'codigo') {
            return (
                <Column key={index} width={columnWith[key]||200} resizable sortable>
                    <HeaderCell>{key}</HeaderCell>
                    <Cell dataKey={key} />
                </Column>
            );
        }
        return null;
    });
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = page * rowsPerPage;
    const paginatedData = data.slice(startIndex, endIndex);
    useEffect(() => {

    }, []);

    const renderEmpty = () => (
        <div>No hay datos disponibles</div>
    );
    const handleRowClick = (rowData:any) => {
        rowClick(rowData)
    };
    return (
        <>
            <Table
                data={paginatedData}
                autoHeight
                affixHeader
                rowHeight={50}
                bordered
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                renderEmpty={renderEmpty}
                onRowClick={handleRowClick}
                style={{ position: 'relative', zIndex: 5 }}
            >
                {columns}
            </Table>
            <Pagination
                count={Math.ceil(data.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
            />
        </>

    );
};

export default ResizableTable;