import {useEffect} from "react";
import {LoggedUser} from "../../configs/userTokenDecrypt";
import {useGetBandejaEntradaQuery} from "../../redux/api/bandejas";
import {useNavigate} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import ResizableTable from "./components/ResizableTable";

const Entrada = () =>{
    const user=LoggedUser()
    const fetchBandeja = useGetBandejaEntradaQuery(user?.usuario??'')
    const navigate = useNavigate();
    useEffect(()=>{
        document.title = 'Asientos Contables - Bandeja Entrada'
    },[])
    const handlerClick=(row:any)=>{
        navigate('/Editar/'+row.codigo)
    }

    return (
        <>
            {fetchBandeja.isLoading?<CircularProgress />:<ResizableTable data={fetchBandeja.data?.Bandeja??[]} rowClick={handlerClick}/>}
        </>
    )
}
export default Entrada