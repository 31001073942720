import {Area, Etapa} from "../../../../redux/api/manageCases";
import {Box,Typography} from "@mui/material";
import React from "react";
import {Aprobador} from "../../../../redux/api/admin/gerencias";
import {EtapaAccordion} from "./etapa";

type props={
    area:Area
    fetchEtapas:any
    aprobadores:Array<Aprobador>
    fetchUsers: any,
    addAprobador:(area:string,etapa:number,aprobador:string)=>void
    removeAprobador:(area:string,etapa:number,aprobador:string)=>void
}
export const AreaBox = ({area,fetchEtapas,aprobadores,fetchUsers,addAprobador,removeAprobador}:props) =>(
    <>
        <Box>
            <Typography variant="h6" sx={{my: 1, borderRadius: '1px',m:1}}>
                {area.nombre}
            </Typography>
            {fetchEtapas.data?.Etapas
                .filter((etapa:Etapa)=>etapa.codigo>1)
                .map((etapa:Etapa,index:number)=>{
                    return (
                        <EtapaAccordion
                            key={index} area={area} etapa={etapa} aprobadores={aprobadores}
                            fetchUsers={fetchUsers} addAprobador={addAprobador} removeAprobador={removeAprobador}/>
                    )
                })
            }
        </Box>
    </>
)