import { catalogosApi } from "../../redux/api/catalogos";
import { useState, useEffect } from "react";
import colorConfigs from "../../configs/colorConfigs";
import Box from '@mui/material/Box';

import Moment from 'react-moment';
import 'moment-timezone';
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { Button } from "@mui/material";
import Grid from '@mui/material/Grid';
import FlagIcon from '@mui/icons-material/Flag';
import styled from '@mui/system/styled';
import SyncIcon from '@mui/icons-material/Sync';
import DomainIcon from '@mui/icons-material/Domain';

import TableCatalogos from '../catalogos/TableCatalogos';

const Sync = () => {
  const [openAlerta, setOpen] = React.useState(0);
  const [descripcionAlerta, setDescripcionAlerta] = useState('');

  const fetchPaises = catalogosApi.endpoints.fetchPaises.useQuery("false")

  const [pais, setPais] = useState("");

  const [triggerEmpresas, fetchEmpresas] = catalogosApi.endpoints.fetchEmpresas.useLazyQuery();

  const [logs, setLogs] = React.useState([]);

  const cerrarAlerta = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(0);
  };
  const queue = async (queue: string) => {

    const url = process.env.REACT_APP_API_ASIENTOS_CONTABLES_MANAGE_CASES! + 'QueueCatalogue/' + queue;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setDescripcionAlerta("El catálogo se empezará a actualizar!");
        setOpen(1);
      }


      )
      .catch((err) => {
        setDescripcionAlerta("El log no se ha podido actualizar!");
        setOpen(2);
        console.error(err);
      });

  };
  const getlog = async () => {

    const url = process.env.REACT_APP_API_ASIENTOS_CONTABLES_CATALOGOS! + 'GetLogs';

    fetch(url)
      .then((response) => response.json())
      .then((data) => {

        setLogs(data);
      }


      )
      .catch((err) => {
        setDescripcionAlerta("El catálogo no se ha podido actualizar!");
        setOpen(2);
        console.error(err);
      });

  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={16} ref={ref} variant="filled" {...props} />;
  });

  const listPaises = fetchPaises.data?.Paises.map((item) =>
    <ListItem sx={{
      "&: hover": {
        backgroundColor: "#D5D5D5"
      },
    }} style={{ cursor: "pointer", backgroundColor: (item["codigo"] === pais ? colorConfigs.sidebar.activeBg : "") }} key={item["codigo"]} onClick={() => setPais(item["codigo"])}>
      <ListItemAvatar>
        <Avatar>
          <FlagIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={item["nombre"]}
        secondary={item["cultura"]}
      />
    </ListItem>,
  );


  const listEmpresas = fetchEmpresas.data?.Empresas.map((item) =>
    <ListItem style={{ cursor: "pointer" }} key={item["codigo"]}

      secondaryAction={
        <IconButton edge="end" aria-label="delete">
          <Button variant="contained" color='primary' title="Actualizar catálogo" component="label" onClick={() => queue("sync empresa " + item["codigo"])}>

            <SyncIcon />

          </Button>
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar>
          <DomainIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={item["nombre"]}
        secondary={
          <div>
            Fecha de actualización: <Moment format="DD/MM/YYYY">
              {item["sync"]}
            </Moment>
            <br></br>
            (<Moment fromNow >
              {item["sync"]}
            </Moment>)
          </div>

        }
      />
    </ListItem>,
  );

  useEffect(() => {
    if (pais !== undefined) {
      let _form = new FormData();
      _form.append("pais", pais)
      triggerEmpresas(_form)

    }
    getlog();
  }, [pais, triggerEmpresas])
  return (
    <div>
      <Box sx={{ flexGrow: 1 }} m={5} pt={0}>
        <Grid container spacing={0}>
          <Grid xs={3}>
            <Item>Paises</Item>
          </Grid>
          <Grid xs={5}>
            {pais && <Item>Empresas</Item>}
          </Grid>

          <Grid xs={4}>
            <Item>Logs</Item>
          </Grid>

          <Grid xs={3}>
            <Item> {listPaises}</Item>
          </Grid>
          <Grid xs={5}>
            {pais && <Item>{listEmpresas}</Item>}
          </Grid>
          <Grid xs={4}>
            <Item><TableCatalogos datos={logs}></TableCatalogos></Item>
          </Grid>

        </Grid>
      </Box>

      <Snackbar style={{ left: "10%" }} open={openAlerta === 1} autoHideDuration={4000} onClose={cerrarAlerta}>
        <Alert onClose={cerrarAlerta} severity="success" sx={{ width: '100%' }}>
          {descripcionAlerta}
        </Alert>
      </Snackbar>
      <Snackbar style={{ left: "10%" }} open={openAlerta === 2} autoHideDuration={4000} onClose={cerrarAlerta}>
        <Alert onClose={cerrarAlerta} severity="error" sx={{ width: '100%' }}>
          {descripcionAlerta}
        </Alert>
      </Snackbar>

    </div >
  );
};
const Item = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  border: '1px solid',
  borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
  padding: theme.spacing(1),
  borderRadius: '4px',
  textAlign: 'center',
}));
export default Sync;